import * as React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { color } from "d3";

interface HeroButtonProps {
  Animation?: string;
  Icon: string;
  Link?: string;
  Modal?: string;
  Color: string;
  Style?: React.CSSProperties;
  ModalToggle?: Function;
  Text?:string;
}

export const HeroButton: React.FC<HeroButtonProps> = ({
  Animation,
  Icon,
  Link,
  Modal,
  Color,
  Style,
  ModalToggle,
  Text
}) => {
  const history = useHistory();
  const routeChange = (path: string | undefined) => {
    if (path && !path.includes("http")) history.push(path);
    if (path && path.includes("http")) window.location.href = path;
  };
  return (
    <a className="showcase-item" style={Style}>
      {Icon !== "fa-coffee" && (
            <Button            
            id="event-cta"
            className={"hero-icon " + Animation + " " + Color}
            data-toggle="modal"
            data-target={Modal}
            onClick={() => {
              !Link && ModalToggle ?ModalToggle():routeChange(Link);
            }}
          >
            {Text?(
              <span className="main fa-layers fa-fw" >
              <i className={"fas " + ((Icon === "fa-none")?"":Icon)}></i>
                <span  style={{fontWeight: 900, color:"black", fontSize:"2em" }} className="fa-layers-text fa-inverse" data-fa-transform="shrink-12 rotate--30">{Text}</span>
             
             </span>
            ):(<i className={"main fa " + Icon}></i>)}
          </Button>
          )}
          {Icon === "fa-coffee" && (
            <Button
            id="event-cta"
            className={"hero-icon " + Animation + " " + Color}
            data-toggle="modal"
            data-target={Modal}
            onClick={() => {
              !Link && ModalToggle?ModalToggle():routeChange(Link);
            }}
          >
              <i className={"main fa " + Icon}></i>
              <div className="cup-steam">
                <i className="steam fa fa-fire"></i>
              </div>
              <i className="steam fa fa-fire"></i>
            </Button>
          )}
      
    </a>
  );
};
