import * as React from "react";
import ReactToPrint from "react-to-print";
import { Button, Collapse } from "reactstrap";

import { ComponentToPrint } from "./ComponentToPrint";

import { Spinner } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const PDF = <FontAwesomeIcon icon={faFilePdf} color="white" size="lg" />;
const networkSuccess = <Spinner animation="border" size="sm" />;
export const AppSummaryPrint = (appcode: any) => {
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef<(() => void) | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  // const reactToPrintTrigger = React.useCallback(() => {
  //   // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
  //   // to the root node of the returned component as it will be overwritten.

  //   // Bad: the `onClick` here will be overwritten by `react-to-print`
  //   // return <a href="#" onClick={() => alert('This will not work')}>Print this out!</a>;

  //   // Good
  //   return <a href="#">Print using a Functional Component</a>;
  // }, []);

  return (
    <div>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint
        // trigger={reactToPrintTrigger}
        trigger={() => (
          <Button variant="contained" color="primary">
            {PDF} Print PDF (USE SAVE AS PDF!!!)
          </Button>
        )}
      />
      {loading && <span>{networkSuccess}</span>}
      <div style={{ visibility: "hidden" }}>
        <Collapse isOpen={loading}>
          <ComponentToPrint
            appcode={appcode.appcode}
            ref={componentRef}
            text={text}
          />
        </Collapse>
      </div>
    </div>
  );
};
