import { KnownAction, RECEIVE_SEARCH, REQUEST_SEARCH } from "./SearchAction";
import { createStore, Store } from "redux";
import { Results } from "../Interfaces";

export interface State {
  results: Results | undefined;
  search: string | undefined;
  stack: string | undefined;
  appcode: string | undefined; //Varchar(3)
  domain: string | undefined;
  isLoading: boolean;
  isError: boolean;
}

export const initialState: State = {
  search: undefined,
  stack: undefined,
  appcode: undefined,
  domain: undefined,
  results: undefined,
  isLoading: false,
  isError: false,
};

export const reducer = (state = initialState, action: KnownAction): State => {
  switch (action.type) {
    case REQUEST_SEARCH: {
      let newstate = { ...state };
      newstate.isLoading = true;
      newstate.results = undefined;
      return newstate;
    }
    case RECEIVE_SEARCH: {
      let newstate = { ...state };
      newstate.results = {
        search: state.search,
        stack: state.stack,
        appcode: state.appcode,
        results: action.payload.results,
        domain: state.domain,
      };
      return newstate;

    }
    default:
      return state;
  }
};

const store: Store<State, KnownAction> = createStore(reducer);

export { store };
