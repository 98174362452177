

import { AppThunkAction } from "..";
import { IChart }   from "@mrblenny/react-flow-chart";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestdiagrams: (
    startDateIndex: string | undefined,
    myCallback: () => void
  ): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();

    if (
      appState &&
      appState.diagramstate &&
      startDateIndex &&
      startDateIndex !== appState.diagramstate.startDateIndex
    ) {
      fetch(process.env.REACT_APP_API_HOST + `/Think/TestFlowRequest`, {
        method: "GET",
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
        .then((response) => response.json() as Promise<IChart[]>)
        .then((data) => {
          dispatch({
            type: "RECEIVE_ALL_DIAGRAMS",
            diagrams: data,
            startDateIndex,
            isError: false,
          });
          myCallback();
        })
        .catch((error) => {
          dispatch({
            type: "RECEIVE_ALL_DIAGRAMS",
            diagrams: appState.diagramstate ? appState.diagramstate.diagrams : [],
            startDateIndex,
            isError: true,
          });
        });

      dispatch({ type: "REQuest_ALL_DIAGRAMS", startDateIndex });
    }
  },
};

export const RECEIVE_ALL_DIAGRAMS = "RECEIVE_ALL_DIAGRAMS";
export const REQuest_ALL_DIAGRAMS = "REQuest_ALL_DIAGRAMS";
export const REQUEST_setExpandedRows = "REQUEST_setExpandedRows";
export const ReceiveSubGroupAction = "ReceiveSubGroupAction";
export const ReceiveCompanyAction = "ReceiveCompanyAction";

interface ReceiveAllDIAGRAMsAction {
  type: "RECEIVE_ALL_DIAGRAMS";
  diagrams: IChart[];
  isError: boolean;
  startDateIndex: string | undefined;
}
interface REQuest_ALL_DIAGRAMS {
  type: "REQuest_ALL_DIAGRAMS";
  startDateIndex: string | undefined;
}
interface REQUEST_setExpandedRows {
  type: "REQUEST_setExpandedRows";
  expandedName: string;
  expandedNums: number[];
  startDateIndex?: string;
}

export type KnownAction =
  ReceiveAllDIAGRAMsAction
  | REQUEST_setExpandedRows
  | REQuest_ALL_DIAGRAMS;