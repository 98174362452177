import * as React from "react";
import { Route, Switch } from "react-router";
import Showcase from "./components/Showcase";

import "./scss/main.css";
import ThinkMain from "./components/Think/Main";
import WatchMain from "./components/Watch/Main";
import DoMain from "./components/Do/Main";
import MainApp from "./Pages/MainApp";
import { AppSummaryPrint } from "./components/AppSummaryPrint";
import NavMenu from "./components/NavMenu";

import AllPosts from "./components/Allposts.js";
import {Resume} from "./Pages/Resume";

export default () => (
  // <Layout>
  <>
    <Switch>
      <Route path="/:section/:appcode" component={NavMenu} />
      <Route path="/:section" component={NavMenu} />
      <Route path="/" component={NavMenu} />
    </Switch>
    <Switch>
      <Route exact path="/Resume" component={Resume} />
      <Route exact path="/" component={Showcase} />
      <Route exact path="/Do/:appcode" component={DoMain} />
      <Route exact path="/Do" component={DoMain} />
      <Route exact path="/Think" component={ThinkMain} />
      <Route exact path="/Think/:appcode" component={ThinkMain} />
      <Route exact path="/Watch/:appcode" component={WatchMain} />
      <Route exact path="/Watch" component={WatchMain} />
      <Route
        exact
        path="/Print/:appcode"
        render={(match: { match: { params: { appcode: any; }; }; }) => (
          <AppSummaryPrint appcode={match.match.params.appcode} />
        )}
      />
      <Route exact path="/Main" component={MainApp} />
    </Switch>
  </>
  // </Layout>
);
