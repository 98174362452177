import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { EffectsCard } from "../cards/EffectsCard";
// import BasicLineChart from "../charts/BasicLineChart";
import { WASSection } from "../WASSection";

interface Props {}
interface State {}

export class Home extends PureComponent<Props, State> {
  state = {};

  render() {
    return (
      <WASSection bgclass="bg8">
        <div className="containereffects row d-flex flex-row">
          <div className="col-sm-2 col-xs-12">
            <EffectsCard Title="Card1" Icon="fa-coffee" Color="tan">
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/WATCH/App1">Activity</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>
        </div>
      </WASSection>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
