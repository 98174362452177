import { AppThunkAction } from ".";
import {
  SiteInfo,
  Server,
  SortWASCI,
  Source,
  Contact,
  UseCase,
  Diagram,
  AppData,
  Monitoring,
  BuildInfo,
  WASCIrule,
} from "./Interfaces";
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  // requestbuilds:
  //   (
  //     controlid: string,
  //     appcode: string,
  //     myCallback: () => void
  //   ): AppThunkAction<KnownAction> =>
  //   (dispatch, getState) => {
  //     // Only load data if it's something we don't already have (and are not already loading)
  //     const appState = getState();

  //     if (appState && appState.wascistate) {
  //       fetch(
  //         process.env.REACT_APP_API_HOST +
  //           `/servers/servers?appcode=` +
  //           appcode,
  //         {
  //           method: "GET",
  //           credentials: "include",
  //           headers: {
  //             pragma: "no-cache",
  //             "cache-control": "no-cache",
  //           },
  //         }
  //       )
  //         .then((response) => response.json() as Promise<Server[]>)
  //         .then((data) => {
  //           dispatch({
  //             type: "RECEIVE_ALL_SERVERS",
  //             payload: { wascis: SortWASCI(data) },
  //             appcode: appcode,
  //             isError: false,
  //           });
  //           myCallback();
  //         })
  //         .catch((error) => {
  //           //TODO what to do on error
  //           // dispatch({
  //           //   type: "RECEIVE_ALL_SERVERS",
  //           //   payload: {
  //           //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
  //           //   },
  //           //   startDateIndex,
  //           //   isError: true,
  //           // });
  //         });

  //       dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
  //     }
  //   },
  requestCurrentJunctions:
    (
      controlid: string,
      appcode: string,
      ServerName: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST +
            `/psmodule/GetCurrentSitesWithApps?ServerName=` +
            ServerName,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<SiteInfo[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_CurrentJunctions",
              payload: { junctions: data, servername: ServerName },
              servername: ServerName,
              controlid: controlid,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_CurrentJunctions",
              payload: {
                junctions: [],
                servername: ServerName,
              },
              servername: ServerName,
              controlid: controlid,
              appcode: appcode,
              isError: true,
            });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },

  requestBuildInfo:
    (
      controlid: string,
      appcode: string,
      ServerName: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST +
            `/psmodule/WASCIBuildInfo?ServerName=` +
            ServerName,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<BuildInfo[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_BUILD_INFO",
              payload: { buildinfo: data, servername: ServerName },
              servername: ServerName,
              controlid: controlid,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_BUILD_INFO",
              payload: {
                buildinfo: [],
                servername: ServerName,
              },
              servername: ServerName,
              controlid: controlid,
              appcode: appcode,
              isError: true,
            });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },
  requestWASCIrules:
    (
      controlid: string,
      appcode: string,
      ServerName: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST +
            `/Do/GetWasciRules?ServerName=` +
            ServerName,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<WASCIrule[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_WASCIRULES",
              payload: { wascirules: data, servername: ServerName },
              servername: ServerName,
              controlid: controlid,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_WASCIRULES",
              payload: {
                wascirules: [],
                servername: ServerName,
              },
              servername: ServerName,
              controlid: controlid,
              appcode: appcode,
              isError: true,
            });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },
  requestprereq:
    (
      controlid: string,
      appcode: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST +
            `/servers/servers?appcode=` +
            appcode,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<Server[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_ALL_SERVERS",
              payload: { wascis: SortWASCI(data) },
              appcode: appcode,
              controlid: controlid,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            //TODO what to do on error
            // dispatch({
            //   type: "RECEIVE_ALL_SERVERS",
            //   payload: {
            //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
            //   },
            //   startDateIndex,
            //   isError: true,
            // });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },
  requestwascis:
    (
      controlid: string,
      appcode: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST +
            `/servers/servers?appcode=` +
            appcode,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<Server[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_ALL_SERVERS",
              payload: { wascis: SortWASCI(data) },
              appcode: appcode,
              controlid: controlid,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            //TODO what to do on error
            // dispatch({
            //   type: "RECEIVE_ALL_SERVERS",
            //   payload: {
            //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
            //   },
            //   startDateIndex,
            //   isError: true,
            // });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },
  requestsource:
    (
      controlid: string,
      appcode: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST + `/think/source?appcode=` + appcode,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<Source[]>)
          .then((data) => {
            let currentAppData =
              appState.wascistate &&
              appState.wascistate.wascis.appData.get(appcode);
            let theAppData: AppData = {
              serverData: currentAppData && currentAppData.serverData,
              diagramData: currentAppData && currentAppData.diagramData,
              contactsData: currentAppData && currentAppData.contactsData,
              monitoringData: currentAppData && currentAppData.monitoringData,
              sourceData: data,
              useCaseData: currentAppData && currentAppData.useCaseData,
            };
            dispatch({
              type: "RECEIVE_APPDATA",
              controlid: controlid,
              payload: theAppData,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            //TODO what to do on error
            // dispatch({
            //   type: "RECEIVE_ALL_SERVERS",
            //   payload: {
            //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
            //   },
            //   startDateIndex,
            //   isError: true,
            // });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },

  requestusecase:
    (
      controlid: string,
      appcode: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST + `/think/usecase?appcode=` + appcode,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<UseCase[]>)
          .then((data) => {
            let currentAppData =
              appState.wascistate &&
              appState.wascistate.wascis.appData.get(appcode);

            let theAppData: AppData = {
              serverData: currentAppData && currentAppData.serverData,
              diagramData: currentAppData && currentAppData.diagramData,
              contactsData: currentAppData && currentAppData.contactsData,
              monitoringData: currentAppData && currentAppData.monitoringData,
              sourceData: currentAppData && currentAppData.sourceData,
              useCaseData: data,
            };
            dispatch({
              type: "RECEIVE_APPDATA",
              controlid: controlid,
              payload: theAppData,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            //TODO what to do on error
            // dispatch({
            //   type: "RECEIVE_ALL_SERVERS",
            //   payload: {
            //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
            //   },
            //   startDateIndex,
            //   isError: true,
            // });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },

  requestcontacts:
    (
      controlid: string,
      appcode: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST + `/think/contacts?appcode=` + appcode,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<Contact[]>)
          .then((data) => {
            let currentAppData =
              appState.wascistate &&
              appState.wascistate.wascis.appData.get(appcode);

            let theAppData: AppData = {
              serverData: currentAppData && currentAppData.serverData,
              diagramData: currentAppData && currentAppData.diagramData,
              contactsData: data,
              monitoringData: currentAppData && currentAppData.monitoringData,
              sourceData: currentAppData && currentAppData.sourceData,
              useCaseData: currentAppData && currentAppData.useCaseData,
            };
            dispatch({
              type: "RECEIVE_APPDATA",
              controlid: controlid,
              payload: theAppData,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            //TODO what to do on error
            // dispatch({
            //   type: "RECEIVE_ALL_SERVERS",
            //   payload: {
            //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
            //   },
            //   startDateIndex,
            //   isError: true,
            // });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },
  requestmonitoring:
    (
      controlid: string,
      appcode: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST +
            `/think/monitoring?appcode=` +
            appcode,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<Monitoring[]>)
          .then((data) => {
            let currentAppData =
              appState.wascistate &&
              appState.wascistate.wascis.appData.get(appcode);

            let theAppData: AppData = {
              serverData: currentAppData && currentAppData.serverData,
              diagramData: currentAppData && currentAppData.diagramData,
              monitoringData: data,
              contactsData: currentAppData && currentAppData.contactsData,
              sourceData: currentAppData && currentAppData.sourceData,
              useCaseData: currentAppData && currentAppData.useCaseData,
            };
            dispatch({
              type: "RECEIVE_APPDATA",
              controlid: controlid,
              payload: theAppData,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            //TODO what to do on error
            // dispatch({
            //   type: "RECEIVE_ALL_SERVERS",
            //   payload: {
            //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
            //   },
            //   startDateIndex,
            //   isError: true,
            // });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },
  requestdiagram:
    (
      controlid: string,
      appcode: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.wascistate) {
        fetch(
          process.env.REACT_APP_API_HOST + `/think/diagram?appcode=` + appcode,
          {
            method: "GET",
            credentials: "include",
            headers: {
              pragma: "no-cache",
              "cache-control": "no-cache",
            },
          }
        )
          .then((response) => response.json() as Promise<Diagram[]>)
          .then((data) => {
            let currentAppData =
              appState.wascistate &&
              appState.wascistate.wascis.appData.get(appcode);

            let theAppData: AppData = {
              serverData: currentAppData && currentAppData.serverData,
              diagramData: data,
              contactsData: currentAppData && currentAppData.contactsData,
              monitoringData: currentAppData && currentAppData.monitoringData,
              sourceData: currentAppData && currentAppData.sourceData,
              useCaseData: currentAppData && currentAppData.useCaseData,
            };
            dispatch({
              type: "RECEIVE_APPDATA",
              controlid: controlid,
              payload: theAppData,
              appcode: appcode,
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            //TODO what to do on error
            // dispatch({
            //   type: "RECEIVE_ALL_SERVERS",
            //   payload: {
            //     wascis: appState.wascistate ? appState.wascistate.wascis : [],
            //   },
            //   startDateIndex,
            //   isError: true,
            // });
          });

        dispatch({ type: "REQUEST_APPDATA", controlid: controlid });
      }
    },
  setExpandedRows:
    (
      expandedName: string,
      expandedNums: number[],
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (
        appState &&
        appState.wascistate &&
        appState.wascistate.expandedRows.get(expandedName) !== expandedNums
      ) {
        dispatch({
          type: "REQUEST_setExpandedRows",
          expandedName: expandedName,
          expandedNums: expandedNums,
        });
        myCallback();
      }
    },

  setModalState:
    (
      ModalName: string,
      isModalOpen: boolean,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      let isModalOpenState =
        appState &&
        appState.wascistate &&
        appState.wascistate.ModalsState.get(ModalName);
      isModalOpenState = isModalOpenState ? isModalOpenState : false;
      if (appState && appState.wascistate && isModalOpen !== isModalOpenState) {
        dispatch({
          type: "REQUEST_SETMODALSTATE",
          ModalName: ModalName,
          ModalOpen: isModalOpen,
        });

        myCallback();
      }
    },
};

export const RECEIVE_ALL_SERVERS = "RECEIVE_ALL_SERVERS";
export const REQUEST_APPDATA = "REQUEST_APPDATA";
export const RECEIVE_CurrentJunctions = "RECEIVE_CurrentJunctions";
export const RECEIVE_WASCIRULES = "RECEIVE_WASCIRULES";
export const RECEIVE_BUILD_INFO = "RECEIVE_BUILD_INFO";
export const REQUEST_setExpandedRows = "REQUEST_setExpandedRows";
export const ReceiveSubGroupAction = "ReceiveSubGroupAction";
export const ReceiveCompanyAction = "ReceiveCompanyAction";
export const REQUEST_SETISCONTROLMODALOPEN = "REQUEST_SETISCONTROLMODALOPEN";
export const REQUEST_SETMODALSTATE = "REQUEST_SETMODALSTATE";
export const RECEIVE_APPDATA = "RECEIVE_APPDATA";

interface RECEIVE_APPDATAAction {
  type: "RECEIVE_APPDATA";
  controlid: string;
  payload: AppData;
  appcode: string;
  isError: boolean;
}
interface RECEIVE_ALL_SERVERSAction {
  type: "RECEIVE_ALL_SERVERS";
  payload: {
    wascis: Server[];
  };
  appcode: string;
  controlid: string;
  isError: boolean;
}
interface REQUEST_APPDATA {
  type: "REQUEST_APPDATA";
  controlid: string;
}
interface REQUEST_setExpandedRows {
  type: "REQUEST_setExpandedRows";
  expandedName: string;
  expandedNums: number[];
}

interface RECEIVE_CurrentJunctions {
  type: "RECEIVE_CurrentJunctions";
  payload: {
    junctions: SiteInfo[];
    servername: string;
  };
  servername: string;
  controlid: string;
  appcode: string;
  isError: boolean;
}

interface RECEIVE_BUILD_INFO {
  type: "RECEIVE_BUILD_INFO";
  payload: {
    buildinfo: BuildInfo[];
    servername: string;
  };
  servername: string;
  controlid: string;
  appcode: string;
  isError: boolean;
}
interface RECEIVE_WASCIRULES {
  type: "RECEIVE_WASCIRULES";
  payload: {
    wascirules: WASCIrule[];
    servername: string;
  };
  servername: string;
  controlid: string;
  appcode: string;
  isError: boolean;
}
interface REQUEST_SETMODALSTATE {
  type: "REQUEST_SETMODALSTATE";
  ModalName: string;
  ModalOpen: boolean;
}
export type KnownAction =
  | RECEIVE_APPDATAAction
  | RECEIVE_ALL_SERVERSAction
  | REQUEST_setExpandedRows
  | REQUEST_APPDATA
  | RECEIVE_CurrentJunctions
  | RECEIVE_BUILD_INFO
  | RECEIVE_WASCIRULES
  | REQUEST_SETMODALSTATE;
