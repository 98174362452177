import * as React from "react";

interface WASSectionProps {
  bgclass: string;
  id?: string;
  className?: string;
}

export const WASSection: React.FC<WASSectionProps> = ({
  bgclass,
  id,
  className,
  children,
}) => {
  return (
    <section className={"showcase " + bgclass + " " + className} id={id}>
      {/* <div id="overlay"></div> */}
      {children}
    </section>
  );
};
