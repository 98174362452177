import * as React from "react";



import OneShotMain from "../Think/OneShot/Main";

type Props = {
  text: string;
  appcode: string;
};

type State = {
  checked: boolean;
};


export class ComponentToPrint extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { checked: false };
  }

  private canvasEl!: HTMLCanvasElement;

  public componentDidMount() {}



  public render() {
    if (this.props.appcode) {
      return (
        <><OneShotMain appcode = {this.props.appcode} grouping={false}></OneShotMain></>
      );
    } else {
      return (<></>);
    }
  }
}
