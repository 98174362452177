import * as React from "react";
import { HeroButton } from "./buttons/HeroButton";
import { CoffeeButton } from "./buttons/CoffeeButton";
import { WASSection } from "./WASSection";
import { Membership } from "./forms/Membership";
import {
  Button,
  Card,
  CardBody,
  CardColumns,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import CredentialComponent from "./credentials/CredentialComponent";
import { CircleNav } from "./cards/CircleNav";

import sassImage from "../images/sass.png";

import sanityClient from "../client.js";
import { Post, Category, slug } from "../store/Interfaces";

import BlockContent from "@sanity/block-content-to-react";
import { FlickrVideo } from "./cards/FlickrVideo";
import { LoomEmbed } from "./cards/LoomEmbed";
// @ts-ignore
import * as Phys from "react-dom-box2d";
const Counter = () => {
  const [count, setCount] = React.useState(0);

  return (
    <div className="counter">
      <p>You could even have other react component inside</p>
      <div className="count">{count}</div>
      <button onClick={() => setCount(count + 1)}>increment +</button>
      <button onClick={() => setCount(count - 1)}>decrement - </button>
    </div>
  );
};
export default class Showcase extends React.PureComponent<
  {},
  { isOpen: boolean; slug: string; postData: Post[]; categoryData: Category[], gravityx: number, gravityy: number, gravityScale: boolean, deviceMotionSet: boolean }
> {
  public state = {
    isOpen: false,
    slug: "",
    postData: [],
    categoryData: [],
    gravityx: 0,//0.29237170472273677,
    gravityy: 9.8,//-0.4226182617406994,
    gravityScale: true,
    deviceMotionSet: false
  };
  private mtoggleslug = (_slug: slug) => {
    _slug !== undefined &&
      this.setState({
        isOpen: !this.state.isOpen,
        slug: _slug.current,
      });
  };
  private mtoggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      slug: this.state.slug,
    });
  };
  Gyro = (e: MouseEvent) => {
    e.preventDefault();
    if (typeof (DeviceMotionEvent) !== "undefined" && typeof ((DeviceMotionEvent as any).requestPermission) === "function") {
      // (optional) Do something before API request prompt.
      (DeviceMotionEvent as any).requestPermission()
        .then((response: string) => {
          // (optional) Do something after API prompt dismissed.
          if (response == "granted") {
            window.addEventListener("devicemotion", (e) => {
              // do something for 'e' here.
              this.setState({
                deviceMotionSet: true
              })
            })
          }
        })
        .catch(console.error)
    } else {
      this.setState({
        deviceMotionSet: true
      })
    }

  }

  public componentDidMount() {

    if (typeof (DeviceMotionEvent) !== "undefined" && typeof ((DeviceMotionEvent as any).requestPermission) === "function") {

    } else {
      this.setState({
        deviceMotionSet: true
      })
    }

    window.addEventListener('deviceorientation', this.handleOrientation, true);


    this.ensureDataFetched();
  }
  private ensureDataFetched() {
    sanityClient
      .fetch(
        `*[_type == "post"]{
    "categoryslugs": categories[]->slug.current,
    "flickrvideos": flickrvideos[]->{url,thumbnail,alt,title,name,slug},
    title,
    slug,
    body,
    mainImage{
      asset->{
      _slug,
      url
    }
  }
}`
      )
      .then((data) => {
        this.setState({
          postData: data,
        });
      })
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == 'category']{
          title,
          subcategory,
          animation,
          link,
          color,
          slug,
          icon,
         'id':*[defined(categories) && _type == 'post' && references(^._id)][0]{
         _id
        }
        }[defined(id)]`
      )
      .then((data) => {
        this.setState({
          categoryData: data,
        });
      })
      .catch(console.error);
  }

  handleOrientation = (event: { absolute: any; alpha: any; beta: any; gamma: any; }) => {
    const { absolute, alpha, beta, gamma } = event;

    if (event.beta) {
      // this.setState({
      //   gravityx: Math.sin(event.gamma * Math.PI / 180),
      //   gravityy: Math.sin((Math.PI / 4) + event.beta * Math.PI / 180),
      //   gravityScale: !this.state.gravityScale
      // });

      this.setState({
        gravityx: Math.sin(event.gamma * Math.PI / 180),
        gravityy: (event.beta >= 0) ? 10 : -10,
        gravityScale: !this.state.gravityScale

      });

    }
    // this.forceUpdate();
  };


  componentWillUnmount() {
    window.removeEventListener(
      'deviceorientation',
      this.handleOrientation,
      true
    );
  }

  public render() {
    const strokeStyle = { "--m": 14, "--tan": 0.41 } as React.CSSProperties;

    let posts: Post[] = this.state.postData;
    let categoryData: Category[] = this.state.categoryData;

    let topOffset = window.innerWidth > 600 ? 0 : 200;
    let leftOffset = window.innerWidth > 600 ? 400 : 0;

    let hasDeviceMotionEvent = (typeof (DeviceMotionEvent) !== "undefined" && typeof ((DeviceMotionEvent as any).requestPermission) === "function");

    return (
      <>
        <WASSection bgclass="bg23 fill max">
          {hasDeviceMotionEvent && !this.state.deviceMotionSet && <Button onClick={(event: any) => {
            this.Gyro(event)
          }}><i className="fa-solid fa-group-arrows-rotate"></i></Button>}
          <Phys.World width={window.innerWidth} height={window.innerHeight - 200} gravity={[this.state.gravityx, this.state.gravityy]} >


            <ul className="showcase-container m-auto text-primary text-center intro-text py-2">

              <Phys.Item shape="circle" left={0} top={0} restitution={0.85} gravityScale={-1}>
                <div className="circle1">
                  <CircleNav
                    Icon="fa-bomb"
                    Color="deeppurple first"
                    Animation="bomb-boom"
                    ModalToggle={this.mtoggleslug}
                    BigText="Things"
                    Icons={
                      categoryData.filter(function (category) {
                        return category.subcategory === "Things";
                      })

                    }
                    ClassName="first"
                  ></CircleNav>
                </div>
              </Phys.Item>



              <Phys.Item shape="circle" left={window.innerWidth > 600 ? 700 : 0} top={topOffset} restitution={0.85} gravityScale={-1}>
                <div className="circle1">
                  <CircleNav
                    Icon="fa-rocket"
                    Color="pink"
                    Animation="rocket-flight"
                    ModalToggle={this.mtoggleslug}
                    BigText="I"
                    Icons={
                      categoryData.filter(function (category) {
                        return category.subcategory === "I";
                      })
                    }
                  ></CircleNav>
                </div>
              </Phys.Item>

              <Phys.Item shape="circle" left={window.innerWidth > 600 ? 1400 : 0} top={topOffset + 200} restitution={0.85} gravityScale={-1}>
                <div className="circle1">
                  <CircleNav
                    Icon="fa-brands fa-safari"
                    Color="melon"
                    Animation="spin-forever"
                    ModalToggle={this.mtoggleslug}
                    BigText="Do"
                    // Icons={[
                    //   { Text: "lje-pto.com" },
                    //   { Icon: "fa-git" },
                    //   { Text: "sanity cms" },
                    //   { Icon: "fa-brands fa-aws" },
                    //   { Icon: "fa-google" },
                    //   { Icon: "fa-bug-slash" },
                    //   { Text: "Credits" },
                    //   { Text: "Privacy" },
                    //   { Text: "Web3" },
                    //   { Text: "Adobe Stuff" },
                    //   { Text: "Davinci Stuff" },
                    // ]}
                    Icons={categoryData.filter(function (category) {
                      return category.subcategory === "Do";
                    })}
                  ></CircleNav>
                </div>
              </Phys.Item>
            </ul></Phys.World>
          <Modal
            isOpen={this.state.isOpen}
            toggle={this.mtoggle}
            backdrop={"static"}
            keyboard={false}
            className="searchmodal"
            size="lg"
          >
            <ModalHeader className="modal-header--sticky" toggle={this.mtoggle}>
              <Button
                onClick={() => {
                  this.mtoggle();
                }}
              >
                Close
              </Button>
            </ModalHeader>
            <ModalBody>
              <div className="showcase-container">
                {posts.map((item, index) => {
                  if (
                    item.categoryslugs &&
                    item.categoryslugs.length > 0 &&
                    item.categoryslugs.some((e) => e !== null) &&
                    item.categoryslugs.some(
                      (e) => e && this.state.slug && e === this.state.slug
                    )
                  ) {
                    return (
                      <div key={index} className="showcase-item">
                        {item.mainImage && <img
                          src={item.mainImage && item.mainImage.asset && item.mainImage.asset.url}
                          alt="Image"
                          className="img-fluid"
                        />}
                        {item.flickrvideos && item.flickrvideos.map((video, index) => {
                          return (<div key={"FlickrVideo" + index} className="showcase-item"><FlickrVideo
                            url={video.url}
                            alt={video.alt}
                            title={video.title}
                            width={video.width}
                            height={video.height}
                            thumbnail={video.thumbnail}
                          ></FlickrVideo></div>);
                        }
                        )}

                        <BlockContent
                          blocks={item.body}
                          projectId={sanityClient.clientConfig.projectId}
                          dataset={sanityClient.clientConfig.dataset}
                        />
                        <LoomEmbed url="https://www.loom.com/embed/7b8105da320a48f49804c68ec328ab8d"></LoomEmbed>
                      </div>
                    );
                  }
                })}
              </div>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </WASSection>
      </>
    );
  }
}
