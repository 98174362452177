import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

interface EffectsCardProps {
  Animation?: string;
  Title: string;
  Icon: string;
  Color: string;
  Link?: string;
}

export const EffectsCard: React.FC<EffectsCardProps> = ({
  Title,
  Icon,
  children,
  Color,
  Animation,
  Link
}) => {
  const history = useHistory();
  const routeChange = (path: string | undefined) => {
    if (path) history.push(path);
  };
  return (
    <div className="effectscard">
      <div className="face face1">
        <div className="content">
          {Icon !== "fa-coffee" && (
            <Button className={"hero-icon " + Animation + " " + Color}
            onClick={() => {
              routeChange(Link);
            }}>
              <i className={"main fa " + Icon}></i>
            </Button>
          )}
          {Icon === "fa-coffee" && (
            <Button className={"hero-icon " + Animation + " " + Color}
            onClick={() => {
              routeChange(Link);
            }}>
              <i className={"main fa " + Icon}></i>
              <div className="cup-steam">
                <i className="steam fa fa-fire"></i>
              </div>
              <i className="steam fa fa-fire"></i>
            </Button>
          )}
          <h3>{Title}</h3>
        </div>
      </div>
      <div className="face face2">
        <div className="content">{children}</div>
      </div>
    </div>
  );
};
