import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { EffectsCard } from "../cards/EffectsCard";
import { WASSection } from "../WASSection";

interface Props {}
interface State {}

export class Home extends PureComponent<Props, State> {
  state = {};

  render() {
    return (
      <WASSection bgclass="bg9">
        <div className="containereffects row d-flex flex-row">
          <div className="col-sm-2 col-xs-12">
            <EffectsCard
              Title="Card1"
              Icon="fa-rocket"
              Color="pink"
              Animation="rocket-flight"
              Link="/Do/App1"
            >
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/Do/App1#DS">Manage</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>
          <div className="col-sm-2 col-xs-12">
            <EffectsCard
              Title="My Site"
              Icon="fa-rocket"
              Color="pink"
              Animation="rocket-flight"
              Link="/Do/CENTRAL"
            >
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/Do/CENTRAL#DS">Manage</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>
        </div>
        <div className="containereffects row d-flex align-items-start flex-row">
          <div className="col-sm-2 col-xs-12">
            <EffectsCard
              Title="groups"
              Icon="fa-rocket"
              Color="pink"
              Animation="rocket-flight"
              Link="/Do/WAS"
            >
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/Do/WAS#DS">Manage</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>
          <div className="col-sm-2 col-xs-12">
            <EffectsCard
              Title="Card1"
              Icon="fa-rocket"
              Color="pink"
              Animation="rocket-flight"
              Link="/Do/CCM"
            >
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/Do/CCM#DS">Manage</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>
        </div>
        <div className="containereffects row d-flex align-items-start flex-row">
          <div className="col-sm-2 col-xs-12">
            <EffectsCard
              Title="do.blah.com"
              Icon="fa-rocket"
              Color="pink"
              Animation="rocket-flight"
              Link="/Do/FCL"
            >
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/Do/">Support Info</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>

          <div className="col-sm-2 col-xs-12">
            <EffectsCard
              Title="blah.com"
              Icon="fa-rocket"
              Color="pink"
              Animation="rocket-flight"
              Link="/Do/PAY"
            >
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/Do/PAY">Support Info</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>

          <div className="col-sm-2 col-xs-12">
            <EffectsCard
              Title="www.blah.com"
              Icon="fa-rocket"
              Color="pink"
              Animation="rocket-flight"
              Link="/Do/App2"
            >
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around bd-highlight flex-wrap">
                  <div className="p-2 bd-highlight">
                    <Link to="/Do/App2">Support Info</Link>
                  </div>
                </div>
              </div>
            </EffectsCard>
          </div>
         
        </div>
      </WASSection>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
