import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { AnyAction, bindActionCreators } from "redux";
import { ApplicationState } from "../../../store";
import * as WASCIState from "../../../store/WASCIReducer";
import * as WASCIAction from "../../../store/WASCI";
import { ThunkDispatch } from "redux-thunk";
import { Card, CardTitle, CardBody, CardSubtitle, Container } from "reactstrap";
import { LocalState } from "../../../store/Interfaces";

// At runtime, Redux will merge together...
type boilerProps = WASCIState.State & // ... state we've requested from the Redux store
  typeof WASCIAction.actionCreators &
  IActions & // ... plus action creators we've requested
  OwnProps &
  RouteComponentProps<{ appcode: string }>; // ... plus incoming routing parameters
interface IActions {
  actions: {
    wasciActions: AnyAction;
  };
}


const waittime = 15000;
class Source extends React.PureComponent<boilerProps> {
  public state: LocalState = {
    interval: setInterval(() => {}, waittime),
  };public componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();

    clearInterval(this.state.interval);

    this.setState({
      interval: setInterval(() => {
        this.ensureDataFetched();
      }, waittime),
    });
  }

  render() {
    let currentAppData = this.props.wascis.appData.get(this.props.appcode);
    let thedata: JSX.Element[] = [];
    currentAppData &&
      currentAppData.sourceData &&
      currentAppData.sourceData.forEach((value, key, map) => {
        thedata.push(
          <Container key={key}>
            <Card>
              <CardBody>
                <CardTitle>{value.name}</CardTitle>
                <CardSubtitle>
                  <a href={value.url} target="_blank" rel="noopener noreferrer">
                    {value.url}
                  </a>
                </CardSubtitle>
              </CardBody>
            </Card>
          </Container>
        );
      });
    if (thedata.length > 0) {
      return (
        <>
          <div className="">
            <div className="row d-flex flex-row justify-content-around align-items-center ml-4">
              <h2>Source Control</h2>
            </div>
            <div className="row d-flex flex-row justify-content-around align-items-center">
              {thedata.map((resultitem: JSX.Element, index) => {
                return resultitem;
              })}
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  private ensureDataFetched() {
    this.props.actions.wasciActions.requestsource(
      "Servers",
      this.props.appcode,
      () => {
        this.forceUpdate();
      }
    );
  }
}

interface OwnProps {
  appcode: string;
}
const mapStateToProps = function (state: ApplicationState, ownProps: OwnProps) {
  return {
    wascis: state.wascistate ? state.wascistate.wascis : undefined,
    appcode: ownProps.appcode,
  };
};

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    actions: {
      wasciActions: bindActionCreators(WASCIAction.actionCreators, dispatch),
    },
  };
}
export default connect(
  mapStateToProps, // Selects which state properties are merged into the component's props
  mapDispatchToProps // Selects which action creators are merged into the component's props
)(Source as any);
