import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { WASSection } from "../../WASSection";
import * as WASCIState from "../../../store/WASCIReducer";
import * as WASCIAction from "../../../store/WASCI";
import { RouteComponentProps } from "react-router";
import { AnyAction, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";

// At runtime, Redux will merge together...
type WASCIProps = WASCIState.State & // ... state we've requested from the Redux store
  typeof WASCIAction.actionCreators & // ... plus action creators we've requested
  temp &
  RouteComponentProps<{ appcode: string }> & // ... plus incoming routing parameters
  MyComponentOwnProps;

interface temp {
  actions: {
    modalActions: AnyAction;
  };
}
export class Main extends PureComponent<WASCIProps> {
  state = {};

  render() {
    return (
      <>
        <WASSection bgclass="bg1">
          {/* <div className="d-flex flex-row">
            <div className="col-4">
              <input
                className="form-control"
                type="text"
                placeholder="Servername"
              />
            </div>
            <div className="col-4">
              <button type="button" className="btn btn-info">
                Info
              </button>
            </div>
          </div> */}
          <div className="d-flex flex-row">
            <div className="col-12">
            </div>
          </div>
        </WASSection>
      </>
    );
  }
}

interface MyComponentOwnProps {
  appcode: string;
}

const mapStateToProps = function (
  state: ApplicationState,
  ownProps: MyComponentOwnProps
) {
  //  console.log('ownprops' + ownProps.subgroup);
  // console.log('state' + state.subgroupcompany.subgroupcompany);
  return {
    appcode: ownProps.appcode,
  };
};

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    actions: {
      modalActions: bindActionCreators(WASCIAction.actionCreators, dispatch),
    },
  };
}

export default connect(
  mapStateToProps, // Selects which state properties are merged into the component's props
  mapDispatchToProps // Selects which action creators are merged into the component's props
)(Main as any);
