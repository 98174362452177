import {
  KnownAction,
  RECEIVE_ALL_DIAGRAMS,
  REQUEST_setExpandedRows,
  REQuest_ALL_DIAGRAMS,
} from "./Diagram";
import { createStore, Store } from "redux";
import { IChart }   from "@mrblenny/react-flow-chart";



export interface State {
   diagrams: IChart[];   
   isLoading: boolean;
   isError: boolean;
   startDateIndex?: string;
   expandedRows : Map<string,number[]>;
}

export const initialState: State = {
  diagrams: [],
  isLoading: false,
  isError: false,
  expandedRows: new Map()
};

export const reducer = (state = initialState, action: KnownAction): State => {
  
  switch (action.type) {
    
    case REQuest_ALL_DIAGRAMS:{
      return {
        diagrams: state.diagrams,
          startDateIndex: action.startDateIndex,
          isLoading: true,
          isError: state.isError,
          expandedRows: state.expandedRows
      };
    }
    case RECEIVE_ALL_DIAGRAMS: 
        return {
          diagrams: action.diagrams,
          isLoading: false,
          isError: action.isError,
          startDateIndex: action.startDateIndex, expandedRows: state.expandedRows
        };

    
    case REQUEST_setExpandedRows: {
      const newExpandedRows = state.expandedRows;
      newExpandedRows.set(action.expandedName,action.expandedNums);
      return {
        diagrams: state.diagrams,
          startDateIndex: action.startDateIndex,
          isLoading: false,
          isError: state.isError,
          expandedRows: newExpandedRows
      };
    };
    default:
      return state;
  }
};

const store: Store<State, KnownAction> = createStore(reducer);


export { store };
