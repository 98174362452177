import * as WASCIState from './WASCIReducer';
import * as CredentialState from './credentials/CredentialReducer';
import * as SearchState from './search/SearchReducer';
import * as ActivityState from './ActivityReducer';
// import * as LoggingState from './Logging/LoggingReducer';
import * as DiagramState from './Think/DiagramReducer';
import * as ChartState  from './charts/ChartReducer';
// The top-level state object
export interface ApplicationState {
    chartstate: ChartState.State | undefined;
    wascistate: WASCIState.State | undefined;
    searchstate: SearchState.State | undefined;
    activitystate: ActivityState.State | undefined;
    credentialstate: CredentialState.State | undefined;
    // loggingstate: LoggingState.State | undefined;
    diagramstate: DiagramState.State | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    wascistate: WASCIState.reducer,
    credentialstate: CredentialState.reducer,
    searchstate: SearchState.reducer,
    activitystate: ActivityState.reducer,
    // loggingstate: LoggingState.reducer,
    diagramstate: DiagramState.reducer,
    chartstate: ChartState.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
