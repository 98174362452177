import {
    KnownAction,
    REQUEST_ALL_ACTIVITYCOUNT,
    RECEIVE_ALL_ACTIVITYCOUNT,
    REQUEST_ALL_WEBAPPCOUNT,
    RECEIVE_ALL_WEBAPPCOUNT,
} from "./ChartAction";
import { createStore, Store } from "redux";

import { Types } from '../charts/types'

export interface ChartState {
    activitycount: Map<string, Types.ActivityCount[]>;
    webappcount: Map<string, Types.WebAppCount[]>;

}

export interface State {
    activitycount: Map<string, Types.ActivityCount[]>;
    webappcount: Map<string, Types.WebAppCount[]>;
    isLoading: boolean;
    isError: boolean;
    expandedRows: Map<string, number[]>;
}

export const initialState: State = {
    activitycount: new Map(),
    webappcount: new Map(),
    isLoading: false,
    isError: false,
    expandedRows: new Map()
};

export const reducer = (state = initialState, action: KnownAction): State => {

    switch (action.type) {

        case REQUEST_ALL_ACTIVITYCOUNT: {
            return {
                activitycount: state.activitycount,
                webappcount: state.webappcount,
                isLoading: true,
                isError: state.isError,
                expandedRows: state.expandedRows
            };
        }
        case RECEIVE_ALL_ACTIVITYCOUNT:
            const newactivitycount = state.activitycount;
            newactivitycount.set(action.webappcode,action.payload.activitycount?action.payload.activitycount:[]);
            return {
                activitycount: newactivitycount,
                webappcount: state.webappcount,
                isLoading: false,
                isError: action.isError,
                expandedRows: state.expandedRows
            };
        case REQUEST_ALL_WEBAPPCOUNT: {
            return {
                activitycount: state.activitycount,
                webappcount: state.webappcount,
                isLoading: true,
                isError: state.isError,
                expandedRows: state.expandedRows
            };
        }
        case RECEIVE_ALL_WEBAPPCOUNT:
            const newwebappcount = state.webappcount;
            newwebappcount.set(action.webappcode,action.payload.webappcount?action.payload.webappcount:[]);
            return {
                activitycount: state.activitycount,
                webappcount: newwebappcount,
                isLoading: false,
                isError: action.isError,
                expandedRows: state.expandedRows
            };
        default:
            return state;
    }
};

const store: Store<State, KnownAction> = createStore(reducer);

export { store };