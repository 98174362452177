import * as React from "react";

interface FlickrVideoProps {
  url: string;
  thumbnail: string;
  title: string;
  width: string;
  height: string;
  alt: string;
}

export const FlickrVideo: React.FC<FlickrVideoProps> = ({
  url,
  thumbnail,
  title,
  width,
  height,
  alt,
}) => {
  return (
    // <video width={width} height={height} controls>
    //   <source src={url} type="video/mp4" />
    //   Your browser does not support the video tag.
    // </video>

    <a
      data-flickr-embed="true"
      // data-header="true"
      // data-footer="true"
      data-context="true"
      href={url}
      title={title}
    >
      <img src={thumbnail} width="100%"  alt={alt} />
    </a>
  );
};
