import React, { MouseEventHandler, PureComponent } from "react";
import { connect } from "react-redux";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import "./NavMenu.css";
// import ControlModal from "./modals/ControlModal.tsx.stash";
import { AnyAction, bindActionCreators } from "redux";
import * as WASCIState from "../store/WASCIReducer";
import * as WASCIAction from "../store/WASCI";
import { ThunkDispatch } from "redux-thunk";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
// import ChatWatcher from "../Chat/ChatWatcher.tsx.stash";

const glasses = require("../images/glasses.png");
// At runtime, Redux will merge together...
type WASCIProps = WASCIState.State & // ... state we've requested from the Redux store
  typeof WASCIAction.actionCreators & // ... plus action creators we've requested
  RouteComponentProps<{ appcode: string }> & // ... plus incoming routing parameters
  temp;

interface temp {
  actions: {
    modalActions: AnyAction;
  };
}
export class NavMenu extends PureComponent<WASCIProps, { isOpen: boolean }> {
  public state = {
    isOpen: false,
  };

  toggleControlModal: MouseEventHandler<HTMLButtonElement> | undefined;

  constructor(props: Readonly<WASCIProps>) {
    super(props);

    this.toggleControlModal = () => {
      this.props.actions.modalActions.setModalState("CONTROL", true, () => {
        this.forceUpdate();
      });
    };
  }
  public render() {
    let isThinkSection: boolean =
      window.location.href.includes("Think") === true;

      const Style = { "maxWidth": "160px" } as React.CSSProperties;
      const NameStyle = { "fontSize": "small" } as React.CSSProperties;
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm box-shadow "
          dark
        >
          <>
            <NavbarBrand tag={Link} to="/">
            <img src={glasses} style={Style}/>
            </NavbarBrand>
            <NavbarBrand >
            <div className="navName">Steve Greblunas</div>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={this.state.isOpen}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/Think">
                    Things
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/Do">
                    I
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/Watch">
                    Do
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/counter">Counter</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/fetch-data">Fetch data</NavLink>
                                </NavItem> */}
                <div className="ml-5"></div>
                <div className="ml-5"></div>
                <div className="ml-5"></div>
                <div className="ml-5"></div>
                <div className="ml-5"></div>

                {/* <button
                  type="button"
                  className="btn btn-info-outline btn-xs ml-5 align-self-end"
                  onClick={this.toggleControlModal}
                  style={{ display: isThinkSection ? "flex" : "none" }}
                >
                  <FontAwesomeIcon icon={faCogs} size="1x" color="white" />
                </button> */}
              </ul>
              {/* {isThinkSection && (
                <ControlModal
                  appcode={this.props.match.params.appcode}
                ></ControlModal>
              )} */}
              
        {/* <ChatWatcher></ChatWatcher> */}
            </Collapse>
          </>
        </Navbar>
      </header>
    );
  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
}

const mapStateToProps = function () {
  //  console.log('ownprops' + ownProps.subgroup);
  // console.log('state' + state.subgroupcompany.subgroupcompany);
  return {};
};

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    actions: {
      modalActions: bindActionCreators(WASCIAction.actionCreators, dispatch),
    },
  };
}

export default connect(
  mapStateToProps, // Selects which state properties are merged into the component's props
  mapDispatchToProps // Selects which action creators are merged into the component's props
)(NavMenu as any);
