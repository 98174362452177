import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

// import DASHMain from "./DASH/Main.tsx.stash";
import Home from "./Home";
type MainProps = RouteComponentProps<{ appcode: string }>; // ... plus incoming routing parameters

interface State {}

export class Main extends PureComponent<MainProps, State> {
  state = {};

  render() {
    if (this.props.match.params.appcode) {
      
      return (
        <>
          {/* <DASHMain appcode = {this.props.match.params.appcode} ></DASHMain> */}
        </>
      );
    } else {
      return <Home></Home>;
    }
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
