import React, { PureComponent } from "react";
import { connect } from "react-redux";
// import BasicLineChart from "../charts/BasicLineChart";
import { WASSection } from "../../WASSection";
import Contacts from "../Contacts/Contacts";
// import { DiagramReadonly } from "../Diagram/DiagramReadonly.tsx";
import Monitoring from "../Monitoring/Monitoring";
import Servers from "../Servers/Servers";
import Source from "../Source/Source";
import UseCaseWidget from "../UseCase/UseCaseWidget";

import { ApplicationState } from "../../../store";
type WASCIProps = SetProps & OwnProps;
interface SetProps {
  
  editmode: boolean;
}
interface OwnProps {
  appcode: string;
  grouping: boolean | undefined;
}

interface State {}

export class Main extends PureComponent<WASCIProps, State> {
  state = {};

  render() {
    return (
      <div>
        <WASSection bgclass="bgGrid" id="DS" className="anchor">
          <div className="row d-flex flex-row justify-content-around align-items-center">
            {/* <DiagramReadonly
              appcode={this.props.appcode}
              editmode={this.props.editmode}
            ></DiagramReadonly> */}

            <Servers appcode={this.props.appcode}></Servers>
          </div>
        </WASSection>
        <WASSection bgclass="bgGrid" id="SC" className="anchor">
          <div className="row d-flex flex-row justify-content-around align-items-center">
            <Source appcode={this.props.appcode}></Source>
            <Contacts appcode={this.props.appcode}></Contacts>
          </div>
        </WASSection>
        <WASSection bgclass="bgGrid" id="U" className="anchor">
          <UseCaseWidget appcode={this.props.appcode} grouping={this.props.grouping} controlIsLoading={false}></UseCaseWidget>
        </WASSection>
        <div className="row d-flex flex-row">
          <div className="col-xs-12 col-lg-12">
            <WASSection bgclass="bgGrid fill" id="M">
              <Monitoring appcode={this.props.appcode}></Monitoring>
            </WASSection>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state: ApplicationState, ownProps: OwnProps) {
  let editmode =
    state.wascistate && state.wascistate.ModalsState.get("EDITMODE");
  editmode = editmode ? editmode : false;

  return {
    editmode: editmode,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
