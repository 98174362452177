import { AppThunkAction } from "..";
import { SortCredential, Credential } from "./CredentialInterfaces";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  users:
    (controlid: string, myCallback: () => void): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.credentialstate) {
        fetch(process.env.REACT_APP_API_HOST + `/users`, {
          method: "GET",
          credentials: "include",
          headers: {
            pragma: "no-cache",
            "cache-control": "no-cache",
          },
        })
          .then((response) => response.json() as Promise<Credential[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_ALL_CREDENTIALS",
              payload: { credentials: SortCredential(data) },
              isError: false,
              controlid: controlid,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_ALL_CREDENTIALS",
              payload: {
                credentials: appState.credentialstate
                  ? appState.credentialstate.credentials
                  : [],
              },
              isError: true,
              controlid: controlid,
            });
          });

        dispatch({ type: "REQUEST_ALL_CREDENTIALS", controlid: controlid });
      }
    },
  remedy:
    (
      controlid: string,
      dataIn: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      if (appState && appState.credentialstate) {
        fetch(process.env.REACT_APP_API_HOST + `/remedy`, {
          method: "POST",
          body: JSON.stringify({ dataIn: dataIn }),
          credentials: "include",
          headers: {
            pragma: "no-cache",
            "cache-control": "no-cache",
            "content-type": "application/json",
          },
        })
          .then((response) => response.json() as Promise<Credential[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_ALL_CREDENTIALS",
              payload: { credentials: SortCredential(data) },
              isError: false,
              controlid: controlid,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_ALL_CREDENTIALS",
              payload: {
                credentials: appState.credentialstate
                  ? appState.credentialstate.credentials
                  : [],
              },
              isError: true,
              controlid: controlid,
            });
            myCallback();
          });

        dispatch({ type: "REQUEST_INSERT_CREDENTIAL", controlid: controlid });
      }
    },
  setModalisOpen:
    (
      isModalOpen: boolean,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (
        appState &&
        appState.credentialstate &&
        isModalOpen !== appState.credentialstate.credentialModalOpen
      ) {
        dispatch({
          type: "REQUEST_SETISMODALOPEN",
          credentialModalOpen: isModalOpen,
        });
      }
    },
};

export const RECEIVE_ALL_CREDENTIALS = "RECEIVE_ALL_CREDENTIALS";
export const REQUEST_ALL_CREDENTIALS = "REQUEST_ALL_CREDENTIALS";
export const REQUEST_SETISMODALOPEN = "REQUEST_SETISMODALOPEN";
export const REQUEST_INSERT_CREDENTIAL = "REQUEST_INSERT_CREDENTIAL";

interface RECEIVE_ALL_CREDENTIALS {
  type: "RECEIVE_ALL_CREDENTIALS";
  payload: {
    credentials: Credential[];
  };
  isError: boolean;
  controlid: string;
}
interface REQUEST_ALL_CREDENTIALS {
  type: "REQUEST_ALL_CREDENTIALS";
  controlid: string;
}

interface REQUEST_INSERT_CREDENTIAL {
  type: "REQUEST_INSERT_CREDENTIAL";
  controlid: string;
}

interface REQUEST_SETISMODALOPEN {
  type: "REQUEST_SETISMODALOPEN";
  credentialModalOpen: boolean;
}
export type KnownAction =
  | RECEIVE_ALL_CREDENTIALS
  | REQUEST_ALL_CREDENTIALS
  | REQUEST_INSERT_CREDENTIAL
  | REQUEST_SETISMODALOPEN;
