import {
  KnownAction,
  RECEIVE_ACTIVITY,
  REQUEST_ACTIVITY,
  RECEIVE_ERRORS,
  REQUEST_ERRORS,
  RECEIVE_BREAKDOWN,
  REQUEST_BREAKDOWN,
  REQUEST_SETSTACK,
  REQUEST_SETDATERANGE,
} from "./ActivityAction";
import { createStore, Store } from "redux";
import { ActivityItem, BreakdownItem, ErrorItem } from "./Interfaces";

export interface State {
  activity: ActivityItem[] | undefined;
  errors: ErrorItem[] | undefined;
  breakdown: BreakdownItem[] | undefined;
  search: string | undefined;
  stack: string | undefined;
  appcode: string | undefined; //Varchar(3)
  domain: string | undefined;
  isLoading: boolean;
  isError: boolean;
  start: Date;
  end: Date;
  label: string | undefined;
}

export const initialState: State = {
  search: undefined,
  stack: undefined,
  appcode: undefined,
  domain: undefined,
  activity: undefined,
  errors: undefined,
  breakdown: undefined,
  isLoading: false,
  isError: false,
  start: new Date(),
  end: new Date(),
  label: (new Date().getDate() + new Date().getMonth() + 1 + new Date().getFullYear()).toString(),
};

export const reducer = (state = initialState, action: KnownAction): State => {
  switch (action.type) {
    case REQUEST_ACTIVITY: {
      let newstate = { ...state };
      newstate.isLoading = true;
      return newstate;
    }
    case RECEIVE_ACTIVITY: {
      let newstate = { ...state };
      newstate.activity = action.payload.activity;
      return newstate;
    }
    case REQUEST_ERRORS: {
      let newstate = { ...state };
      newstate.isLoading = true;
      return newstate;
    }
    case RECEIVE_ERRORS: {
      let newstate = { ...state };
      newstate.errors = action.payload.errors;
      return newstate;
    }
    case REQUEST_BREAKDOWN: {
      let newstate = { ...state };
      newstate.isLoading = true;
      return newstate;
    }
    case RECEIVE_BREAKDOWN: {
      let newstate = { ...state };
      newstate.breakdown = action.payload.breakdown;
      return newstate;
    }
    case REQUEST_SETSTACK: {
      let newstate = { ...state };
      newstate.stack = action.stack;
      return newstate;
    }
    case REQUEST_SETDATERANGE: {
      let newstate = { ...state };
      newstate.start = action.start;      
      newstate.end = action.end;
      newstate.label = action.label;
      return newstate;
    }
    default:
      return state;
  }
};

const store: Store<State, KnownAction> = createStore(reducer);

export { store };
