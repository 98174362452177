import {
  KnownAction,
  RECEIVE_APPDATA,
  RECEIVE_ALL_SERVERS,
  REQUEST_setExpandedRows,
  RECEIVE_CurrentJunctions,
  REQUEST_APPDATA,
  REQUEST_SETMODALSTATE,
  RECEIVE_WASCIRULES,
  RECEIVE_BUILD_INFO,
} from "./WASCI";
import { createStore, Store } from "redux";
import {
  AppPool,
  SiteInfo,
  Server,
  Site,
  AppData,
  WASCIrule,
  BuildInfo,
} from "./Interfaces";

export interface WASCIState {
  appData: Map<string, AppData>;
}
export interface State {
  expandedRows: Map<string, number[]>;
  wascis: WASCIState;
  isLoading: Map<string, boolean>;
  random: number;
  ModalsState: Map<string, boolean>;
  isError: boolean;
  loadDate: Date | undefined;
}

export const initialState: State = {
  wascis: { appData: new Map() },
  isLoading: new Map(),
  random: Math.floor(Math.random() * Math.floor(1000)),
  ModalsState: new Map(),
  isError: false,
  expandedRows: new Map(),
  loadDate: undefined,
};

export const reducer = (state = initialState, action: KnownAction): State => {
  switch (action.type) {
    case REQUEST_APPDATA: {
      let newstate = { ...state };
      newstate.isLoading.set(action.controlid, true);
      // newstate.random = Math.floor(Math.random() * Math.floor(1000));
      return newstate;
    }

    case RECEIVE_APPDATA: {
      const newwascis = state.wascis;
      newwascis.appData.set(action.appcode, action.payload);

      let newstate = { ...state };
      newstate.isLoading.set(action.controlid, false);
      // newstate.random = Math.floor(Math.random() * Math.floor(1000));
      newstate.wascis = newwascis;
      newstate.isError = action.isError;
      newstate.loadDate = new Date(Date.now());
      return newstate;
    }

    case RECEIVE_ALL_SERVERS: {
      let newwascis1 = state.wascis;

      newwascis1 = buildState(
        action.appcode,
        state.wascis,
        action.payload.wascis
      );

      let newstate = { ...state };
      newstate.isLoading.set(action.controlid, false);
      // newstate.random = Math.floor(Math.random() * Math.floor(1000));
      newstate.wascis = newwascis1;
      newstate.isError = action.isError;
      newstate.loadDate = new Date(Date.now());
      return newstate;
    }
    case RECEIVE_CurrentJunctions: {
      const newwascis = addDataToState(
        action.appcode,
        state.wascis,
        action.payload.servername,
        undefined,
        undefined,
        action.payload.junctions,
        null,
        null,
        null,
        null
      );

      let newstate = { ...state };
      newstate.wascis = newwascis;
      newstate.isLoading.set(action.controlid, false);
      // newstate.random = Math.floor(Math.random() * Math.floor(1000));
      newstate.isError = action.isError;
      return newstate;
    }
    case RECEIVE_WASCIRULES: {
      const newwascis = addDataToState(
        action.appcode,
        state.wascis,
        action.payload.servername,
        undefined,
        undefined,
        null,
        action.payload.wascirules,
        null,
        null,
        null
      );

      let newstate = { ...state };
      newstate.wascis = newwascis;
      newstate.isLoading.set(action.controlid, false);
      // newstate.random = Math.floor(Math.random() * Math.floor(1000));
      newstate.isError = action.isError;
      return newstate;
    }
    case RECEIVE_BUILD_INFO: {
      const newwascis = addDataToState(
        action.appcode,
        state.wascis,
        action.payload.servername,
        undefined,
        undefined,
        null,
        null,
        action.payload.buildinfo,
        null,
        null
      );

      let newstate = { ...state };
      newstate.wascis = newwascis;
      newstate.isLoading.set(action.controlid, false);
      // newstate.random = Math.floor(Math.random() * Math.floor(1000));
      newstate.isError = action.isError;
      return newstate;
    }
    case REQUEST_setExpandedRows: {
      const newExpandedRows = state.expandedRows;
      newExpandedRows.set(action.expandedName, action.expandedNums);

      let newstate = { ...state };
      newstate.expandedRows = newExpandedRows;
      return newstate;
    }

    case REQUEST_SETMODALSTATE: {
      let newstate = { ...state };
      newstate.ModalsState.set(action.ModalName, action.ModalOpen);
      return newstate;
    }
    default:
      return state;
  }
};

const store: Store<State, KnownAction> = createStore(reducer);

export { store };

function buildState(appcode: string, wascis: WASCIState, servers: Server[]) {
  const newwascis = wascis;
  let currentApp = newwascis.appData.get(appcode);
  if (currentApp !== undefined && currentApp.serverData !== undefined) {
    servers.forEach((element) => {
      let currentServer =
        currentApp &&
        currentApp.serverData &&
        currentApp.serverData.get(element.servername);
      if (currentServer !== undefined) {
        currentApp &&
          currentApp.serverData &&
          currentApp.serverData.set(element.servername, {
            appcode: element.appcode,
            servername: element.servername,
            description: element.description,
            status: element.status,
            stack: element.stack,
            junctions: currentServer.junctions,
            wascirules: currentServer.wascirules,
            buildinfo: currentServer.buildinfo,
            sites: currentServer.sites,
            apppools: currentServer.apppools,
          });
      } else {
        currentApp &&
          currentApp.serverData &&
          currentApp.serverData.set(element.servername, {
            appcode: element.appcode,
            servername: element.servername,
            description: element.description,
            status: element.status,
            stack: element.stack,
            junctions: null,
            wascirules: null,
            buildinfo: null,
            sites: null,
            apppools: null,
          });
      }
    });
  } else {
    let newApp = {
      serverData: new Map(),
      diagramData: [],
      contactsData: [],
      monitoringData: [],
      sourceData: [],
      useCaseData: [],
    };
    servers.forEach((element) => {
      newApp.serverData.set(element.servername, {
        appcode: element.appcode,
        servername: element.servername,
        description: element.description,
        status: element.status,
        stack: element.stack,
        junctions: null,
        sites: null,
        apppools: null,
      });
    });
    newwascis.appData.set(appcode, newApp);
  }

  return newwascis;
}
function addDataToState(
  appcode: string,
  wascis: WASCIState,
  servername: string,
  description: string | undefined,
  stack: string | undefined,
  junctions: SiteInfo[] | null,
  wascirules: WASCIrule[] | null,
  buildinfo: BuildInfo[] | null,
  sites: Site[] | null,
  apppools: AppPool[] | null
) {
  const newwascis = wascis;
  let currentApp = newwascis.appData.get(appcode);
  if (currentApp !== undefined) {
    let currentServer =
      currentApp &&
      currentApp.serverData &&
      currentApp.serverData.get(servername);
    if (currentServer !== undefined) {
      currentApp &&
        currentApp.serverData &&
        currentApp.serverData.set(servername, {
          appcode: currentServer.appcode,
          servername: servername,
          description: currentServer.description,
          status: currentServer.status,
          stack: currentServer.stack,
          junctions: junctions === null ? currentServer.junctions : junctions,
          wascirules:
            wascirules === null ? currentServer.wascirules : wascirules,
          buildinfo: buildinfo === null ? currentServer.buildinfo : buildinfo,
          sites: sites === null ? currentServer.sites : sites,
          apppools: apppools === null ? currentServer.apppools : apppools,
        });
    } else {
      currentApp &&
        currentApp.serverData &&
        currentApp.serverData.set(servername, {
          appcode: "",
          servername: servername,
          description: description,
          status: 0,
          stack: description,
          junctions: junctions,
          wascirules: wascirules,
          buildinfo: buildinfo,
          sites: sites,
          apppools: apppools,
        });
    }
  } else {
    let newApp = {
      serverData: new Map(),
      diagramData: [],
      contactsData: [],
      monitoringData: [],
      sourceData: [],
      useCaseData: [],
    };
    newApp.serverData.set(servername, {
      appcode: "",
      servername: servername,
      junctions: junctions,
      wascirules: wascirules,
      buildinfo: buildinfo,
      sites: sites,
      apppools: apppools,
    });
    newwascis.appData.set(appcode, newApp);
  }

  return newwascis;
}
