import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faLightbulb,
  faGraduationCap,
  faHome,
  faUserPlus,
  faRocket,
  faEnvelope,
  faFootballBall,
  faSun,
  faSkiing,
  faChild,
  faPaw,
  faMapMarkedAlt,
  faLaptopCode,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";

type Props = {
  text: string;
};

type State = {
  checked: boolean;
};

const questionIcon = <FontAwesomeIcon icon={faQuestion} color="#446cb2" />;
const UserPlus = <FontAwesomeIcon icon={faUserPlus} color="#446cb2" />;
const HomeIcon = <FontAwesomeIcon icon={faHome} color="#446cb2" />;
const GraduationCap = (
  <FontAwesomeIcon icon={faGraduationCap} color="#446cb2" />
);
const RocketIcon = <FontAwesomeIcon icon={faRocket} color="#446cb2" />;

export class Resume extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { checked: false };
  }

  private canvasEl!: HTMLCanvasElement;

  public componentDidMount() {}

  private handleCheckboxOnChange = () =>
    this.setState({ checked: !this.state.checked });

  private setRef = (ref: HTMLCanvasElement) => (this.canvasEl = ref);

  public render() {
    return (
      <div className="resume">
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="col-12">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="resume-name">Stephen Greblunas</div>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="resume-title">Senior Software Engineer</div>
            </div>
          </div>
        </div>
        <hr className="m-2" />
        <div className="d-flex flex-row">
          <div className="col-8">
            <div className="d-flex flex-row">
              <div className="col-12">
                <div className="d-flex">
                  <h3 className="resume-heading">Profile</h3>
                  <div className="resume-icon ml-2 mt-2">{UserPlus}</div>
                </div>
                <div>
                  <p>
                    Experienced software developer focused on web and API
                    development. I value the skills, practices, and unique
                    methodology learned as well as the particular
                    tools and coding languages used.
                  </p>
                  <p>
                    I have gained a unique experience through the completion of
                    projects for customer-focused and call-center-focused
                    applications. I have excelled as a leader as well as an
                    individual contributor. I am confident with my understanding
                    of effective practices for creating and changing software.
                  </p>
                  {/* <p>
                  Weighing the risk of change vs risk of not
                    changing is at the forefront of software development of
                    critical applications.
                  </p> */}
                  <p>
                    Looking forward to contributing to the success of companies
                    or projects that I join.
                  </p>
                </div>
              </div>
            </div>

            <hr />
            <div className="d-flex flex-row">
              <div className="col-12">
                <div className="d-flex">
                  <h3 className="resume-heading">Employment History</h3>
                  <div className="resume-icon ml-2 mt-2">{RocketIcon}</div>
                </div>
                <div className="resume-history-subtitle">
                  Senior and Lead Developer at Frontier Communications, Dallas,
                  PA
                </div>
                <div className="resume-dates">October 2003 - Present</div>
                <div>
                  <p>
                    I have been with Frontier (via Commonwealth Telephone) for
                    17 years. I held various development and DevOps positions. I
                    completed numerous critical projects. I led teams of
                    developers and also have developed projects as an individual
                    contributor. Projects include public websites, a payment
                    processing system, and call-center management applications. I
                    was a key contributor to PCI compliance and security
                    remediation initiatives for the company. I have proven
                    myself with new teams over and over as the company has grown
                    by acquisition.
                  </p>
                </div>
                <div className="resume-history-subtitle">
                  Engineer at Astropower, Newark, DE
                </div>
                <div className="resume-dates">2001 - 2003</div>
                <div>
                  <p>
                    Worked on programming for applications used in manufacturing
                    for testing solar panels.
                  </p>
                </div>
              </div>
            </div>

            <hr />
            <div className="d-flex flex-row">
              <div className="col-12">
                <div className="d-flex">
                  <h3 className="resume-heading">Education</h3>
                  <div className="resume-icon ml-2 mt-2">
                    <FontAwesomeIcon icon={faGraduationCap} color="#446cb2" />
                  </div>
                </div>
                <div className="resume-history-subtitle">
                  University of Delaware
                </div>
                <div className="resume-dates">September 1997-May 2002</div>
                <div>
                  <p>
                    Bachelor of Electrical Engineering with a Computer
                    Engineering concentration. Minor in computer science.
                  </p>
                </div>
              </div>
            </div>

            <hr />
            <div className="d-flex flex-row resume-bottom">
              <div className="col-12">
                <div className="d-flex">
                  <h3 className="resume-heading">Home Life</h3>
                  <div className="resume-icon ml-2 mt-2">
                    <FontAwesomeIcon icon={faHome} color="#446cb2" />
                  </div>
                  <div className="resume-icon ml-2 mt-2">
                    <FontAwesomeIcon icon={faChild} color="#446cb2" />
                  </div>
                  <div className="resume-icon ml-2 mt-2">
                    <FontAwesomeIcon icon={faPaw} color="#446cb2" />
                  </div>
                  <div className="resume-icon ml-2 mt-2">
                    <FontAwesomeIcon icon={faFootballBall} color="#446cb2" />
                  </div>
                  <div className="resume-icon ml-2 mt-2">
                    <FontAwesomeIcon icon={faSun} color="#446cb2" />
                  </div>
                  <div className="resume-icon ml-2 mt-2">
                    <FontAwesomeIcon icon={faSkiing} color="#446cb2" />
                  </div>
                </div>
                <div>Married with 3 boys.</div>
                <div>Active in school PTO and on PTO board.</div>
                <div>
                  Enjoy photography, video editing, and playing sports with my
                  family.
                </div>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="d-flex flex-row">
              <div className="col-2 resume-icon ml-2 mt-3">
                <FontAwesomeIcon
                  icon={faCommentDots}
                  size="lg"
                  color="#446cb2"
                />
              </div>
              <div className="col-10">
                <div className="resume-heading">570-406-2896</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="col-2 resume-icon ml-2 mt-3">
                <FontAwesomeIcon icon={faEnvelope} size="lg" color="#446cb2" />
              </div>
              <div className="col-10">
                <div className="resume-heading">greblunas@gmail.com</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="col-2 resume-icon ml-2 mt-3">
                <FontAwesomeIcon
                  icon={faMapMarkedAlt}
                  size="lg"
                  color="#446cb2"
                />
              </div>
              <div className="col-10">
                <address>
                  510 Jackson rd.
                  <br></br>
                  Dallas, PA 18612
                </address>
              </div>
            </div>

            <div className="d-flex flex-row resume-skills">
              <div className="col-2 resume-icon ml-2 mt-2">
                <FontAwesomeIcon
                  icon={faLaptopCode}
                  size="lg"
                  color="#446cb2"
                />
              </div>
              <div className="col-10 ">
                <div className="d-flex flex-row flex-wrap">
                  <div className="col-12">C#</div>
                  <div className="col-12">JavaScript</div>
                  <div className="col-12">TypeScript</div>
                  <div className="col-12">.NET core</div>
                  <div className="col-12">SQL Server</div>

                  <div className="col-12">ReactJS</div>
                  <div className="col-12">Bootstrap</div>

                  <div className="col-12">CSS</div>
                  <div className="col-12">Sass</div>

                  <div className="col-12">Sitecore</div>
                  <div className="col-12">Sitefinity</div>

                  <div className="col-12">VSCode</div>
                  <div className="col-12">npm</div>
                  <div className="col-12">Git</div>
                </div>
              </div>
            </div>
            <br />
            <div className="d-flex flex-row resume-skills">
              <div className="col-2 resume-icon ml-2 mt-2">
                <FontAwesomeIcon icon={faLightbulb} size="lg" color="#446cb2" />
              </div>
              <div className="col-10 ">
                <div className="d-flex flex-row flex-wrap">
                  <div className="col-12">Leadership</div>
                  <div className="col-12">Teamwork</div>
                  <div className="col-12">Critical Thinking</div>
                  <div className="col-12">Problem Solving</div>
                  <div className="col-12">Time Management</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
