import React from "react";
import { GlobalStyle } from "../globalStyles";
import { lazy, Suspense } from "react";

const Home = lazy(() => import("./Home"));
const Header = lazy(() => import("../components/Header/index"));
const Footer = lazy(() => import("../components/Footer/index"));
const ScrollToTop = lazy(() => import("../components/ScrollToTop/index"));

function MainApp() {
    return (
      <>
        <Suspense fallback={null}>
          <GlobalStyle />
          {/* Hi There! */}
          <ScrollToTop />
          <Header />
          <Home />
          <Footer />
        </Suspense>
      </>
    );
  }
  
  export default MainApp;