import * as React from "react";

interface LoomEmbedProps {
    url: string;
}

export const LoomEmbed: React.FC<LoomEmbedProps> = ({
    url
}) => {
    const wrapperStyle = {
        "position": "relative",
        "paddingBottom": "74.35114503816794%",
        "height": 0,
    } as React.CSSProperties;
    const iframeStyle = {
        "position": "absolute",
        "top": 0,
        "left": 0,
        "width": "100%",
        "height": "100%",
    } as React.CSSProperties;

    return (
        // <video width={width} height={height} controls>
        //   <source src={url} type="video/mp4" />
        //   Your browser does not support the video tag.
        // </video>
        <div style={wrapperStyle}>
            <iframe src={url}
                frameBorder="0"
                allowFullScreen style={iframeStyle}>

            </iframe>
        </div>

    );
};
