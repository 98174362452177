import * as React from "react";
import { HeroButton } from "../buttons/HeroButton";
import { CoffeeButton } from "../buttons/CoffeeButton";
import {
  Button,
  Card,
  CardBody,
  CardColumns,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Category } from "../../store/Interfaces";

interface Circle {
  Text?: string;
  Icon?: string;
  slug?: string;
}

interface CircleNavProps {
  Animation?: string;
  Icon: string;
  Link?: string;
  Modal?: string;
  Color: string;
  Style?: React.CSSProperties;
  ModalToggle?: Function;
  BigText: string;
  Icons: Category[];
  ClassName?: string;
}

export const CircleNav: React.FC<CircleNavProps> = ({
  Animation,
  Icon,
  Link,
  Modal,
  Color,
  Style,
  ModalToggle,
  BigText,
  Icons,
  ClassName,
}) => {
  const history = useHistory();
  const routeChange = (path: string | undefined) => {
    if (path) history.push(path);
  };
  const strokeStyle = {
    "--m": Icons.length,
    "--tan": 0.41,
  } as React.CSSProperties;

  const localToggle = (item: Circle) => {
    ModalToggle && ModalToggle(item.slug);
  };
  return (
    <div
      className={"cn-wrapper opened-nav small-buttons " + ClassName}
      style={strokeStyle}
    >
      {" "}
      <Button
        id="event-cta"
        className={"hero-icon " + Animation + " " + Color + " big-button"}
        data-toggle="modal"
        data-target={Modal}
        onClick={() => {
          ModalToggle ? ModalToggle() : routeChange(Link);
        }}
      >
        <span className="main fa-layers fa-fw">
          <i className={"fas " + (Icon === "fa-none" ? "" : Icon)}></i>
          <span
            style={{ fontWeight: 900, color: "black" }}
            className="fa-layers-text fa-inverse"
            data-fa-transform="shrink-10 "
          >
            {BigText}
          </span>
        </span>
      </Button>
      {Icons.map((item, index) => {
        return (
          <HeroButton
            key={"button" + index}
            Style={{ "--i": index + 1 } as React.CSSProperties}
            Icon={item.icon ? item.icon : "none"}
            Color={item.color}
            Animation={item.animation}
            ModalToggle={() => {
              localToggle(item);
            }}
            Link={item.link}
            Text={(item.title && !item.icon) ? item.title : ""}
          ></HeroButton>
        );
      })}
    </div>
  );
};
