export interface Category {
  title: string;
  color: string;
  animation: string;
  slug: string;
  subcategory: string;
  icon: string;
  link: string;
}

export interface slug {
  current: string;
}
export interface SanityImage {
  asset: any;
  url: string;
}
export interface Post {
  body: any;
  title: string;
  slug: string;
  mainImage: SanityImage;
  categoryslugs: string[];
  flickrvideos: FlickrVideo[];
}

export interface FlickrVideo {
  name: string;
  slug: string;
  url: string;
  thumbnail: string;
  title: string;
  width: string;
  height: string;
  alt: string;
}
export interface MembershipCandidate {
  firstname: string;
  lastname: string;
  email: string;
  address: string;
  phone: string;
  city: string;
  state: string;
  zip: string;

  childrenArray: Child[];
}

export interface Child {
  firstname: string;
  lastname: string;
  gradesection: string;
}

export interface LocalState {
  interval: any;
}

export interface AppData {
  serverData: Map<string, Server> | undefined;
  diagramData: Diagram[] | undefined;
  contactsData: Contact[] | undefined;
  monitoringData: Monitoring[] | undefined;
  sourceData: Source[] | undefined;
  useCaseData: UseCase[] | undefined;
}

export interface Diagram {
  name: string;
  ichart: string;
  id: number | undefined;
  appcode: string;
}

export interface LogMessage {
  message: string;
  status: string;
  user: string;
}

export interface Monitoring {
  name: string;
  url: string;
  type: string;
}

export interface Contact {
  name: string;
  email: string;
  role: string;
}

export interface Source {
  name: string;
  url: string;
}

export interface ActivityDetails {
  ApplicationID: string;
  EmployeeID: string;
  UserID: string;
  AccountNumber: string;
  UniversalServiceId: string;
  TelephoneNumber: string;
  SequenceNumber: string;
  Sessionid: string;
  webappcode: string;
  errormessage: string;
  logdate: Date;
  faultmessage: string;
  Status: string;
}
export interface ErrorDetails {
  errormessage: string;
  logdate: Date;
  errorxml: string;
  errorjson: string;
}

export interface UseCase {
  id: number;
  description: string;
  theimage: string;
  groupid: number;
  appcode: string;
  ContentType: string;
  url: string;
}

export interface WASCIrule {
  id: number | undefined;
  cloneurl: string;
  servername: string;
  reponame: string;
  revision: string;
  branch: string;
  buildscript: string;
  pat: string;
  solutionfilename: string;
  csprojfilename: string;
  appname: string;
  latestrevision: string;
  heartbeatdt: Date;
  createdt: Date;
  updateddt: Date;
}

export interface BuildInfo {
  FullName: string;
}

export interface BuildQueue {
  id: number;
  servername: string;
  reponame: string;
  branch: string;
  revision: string;
  config: string;
  status: string;
  logfilepath: string;
  publishpath: string;
  csprojfile: string;
  solutionfile: string;
  type: string;
  createdt: Date;
  startdt: Date;
  updateddt: Date;
  killdt: Date;
}
export interface StageCandidate {
  servername: string;
  targetserver: string;
  buildpath: string;
}
export interface BuildCandidate {
  servername: string;
  ruleId: number | undefined;
  config: string | undefined;
}
export interface WebAppPool {
  id: number | undefined;
  servername: string;
  thepool: string;
  action: string;
}
export interface JunctionLink {
  id: number | undefined;
  servername: string;
  solutionpath: string;
  reponame: string;
  revision: string;
  branch: string;
  Configuration: string;
  publishpath: string;
  domain: string;
  appname: string;
  JunctionDirectory: string;
}
export interface Server {
  servername: string;
  description: string | undefined;
  stack: string | undefined;
  status: number;
  appcode: string; //Varchar(3)
  wascirules: WASCIrule[] | null;
  buildinfo: BuildInfo[] | null;
  junctions: SiteInfo[] | null;
  sites: Site[] | null;
  apppools: AppPool[] | null;
}

export interface SiteInfo {
  Name: string;
  LinkPath: string;
  Parent: string;
  PhysicalPath: string;
}

export interface Site {
  name: string;
  path: string;
}

export interface AppPool {
  name: string;
}

export interface Results {
  search: string | undefined;
  stack: string | undefined;
  appcode: string | undefined; //Varchar(3)
  results: ResultItem[];
  domain: string | undefined;
}
export interface ResultItem {
  method: string;
  location: string;
  sessionid: string;
  data: string;
  logdate: Date;
}

export interface ActivityItem {
  stack: string | undefined;
  appcode: string | undefined; //Varchar(3)
  totalsessions: number;
  completedsessions: number;
  errorsessions: number;
  successfulsessions: number;
  logdate: Date;
}

export interface Response {
  status: number;
  description: string;
}

export interface ErrorItem {
  location: string;
  method: string;
  count: number;
}
export interface BreakdownItem {
  webappcode: string;
  totalsessions: number;
}

export function SortWASCI(props: any[]) {
  let sorted = [...props];

  sorted.sort((a: Server, b: Server) => {
    if (a.stack === null && b.stack === null) {
      return 0;
    }
    if (a.stack === null) {
      return 1;
    }
    if (a.stack === null) {
      return -1;
    }
    if (a.stack !== null && b.stack !== null) {
      if (a.stack && b.stack && a.stack < b.stack) {
        return -1;
      }
      if (a.stack && b.stack && a.stack > b.stack) {
        return 1;
      }
    }
    return 0;
  });

  sorted.sort((a: Server, b: Server) => {
    if (a.servername === null && b.servername === null) {
      return 0;
    }
    if (a.servername === null) {
      return 1;
    }
    if (a.servername === null) {
      return -1;
    }
    if (a.servername !== null && b.servername !== null) {
      if (a.servername < b.servername) {
        return -1;
      }
      if (a.servername > b.servername) {
        return 1;
      }
    }
    return 0;
  });

  return sorted;
}

export function SortBuild(props: any[]) {
  let sorted = [...props];

  sorted.sort((a: BuildQueue, b: BuildQueue) => {
    if (a.updateddt === null && b.updateddt === null) {
      return 0;
    }
    if (a.updateddt === null) {
      return 1;
    }
    if (a.updateddt === null) {
      return -1;
    }
    if (a.updateddt !== null && b.updateddt !== null) {
      if (
        a.updateddt &&
        b.updateddt &&
        new Date(a.updateddt) < new Date(b.updateddt)
      ) {
        return 1;
      }
      if (
        a.updateddt &&
        b.updateddt &&
        new Date(a.updateddt) > new Date(b.updateddt)
      ) {
        return -1;
      }
    }
    return 0;
  });

  // sorted.sort((a: BuildQueue, b: BuildQueue) => {
  //   if (a.status === null && b.status === null) {
  //     return 0;
  //   }
  //   if (a.status === null) {
  //     return 1;
  //   }
  //   if (a.status === null) {
  //     return -1;
  //   }
  //   if (a.status !== null && b.status !== null) {
  //     if (a.status < b.status) {
  //       return -1;
  //     }
  //     if (a.status > b.status) {
  //       return 1;
  //     }
  //   }
  //   return 0;
  // });

  return sorted;
}
