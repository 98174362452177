import { AppThunkAction } from "..";
import { ResultItem } from "../Interfaces";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestsearch:
    (
      search: string,
      appcode: string,
      stack: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      stack = stack ? stack : "prod";

      if (appState && appState.searchstate) {
        let mindatetime = "";

        if (appState.activitystate) {
          let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
            appState.activitystate.start
          );
          let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
            appState.activitystate.start
          );
          let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
            appState.activitystate.start
          );

          mindatetime = `${ye}-${mo}-${da}`;
        }

        const fullurl =
          "?columns=logdate%2cmethod%2clocation%2cSessionid%2cdata&groupby=logdate%2cmethod%2clocation%2cSessionid%2cdata&mindatetime=" +
          mindatetime +
          "&appdatalike=" +
          search +
          "&stack=" +
          stack;

        //fetch(process.env.REACT_APP_API_HOST + `/watch/search?search=` + search + `&stack=` + stack + `&appcode=` + appcode, {
        fetch(process.env.REACT_APP_API_HOST + `/watch/search` + fullurl, {
          method: "GET",
          credentials: "include",
          headers: {
            pragma: "no-cache",
            "cache-control": "no-cache",
          },
        })
          .then((response) => response.json() as Promise<ResultItem[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_SEARCH",
              payload: { results: data },
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_SEARCH",
              payload: {
                results:
                  appState.searchstate && appState.searchstate.results
                    ? appState.searchstate.results.results
                    : [],
              },
              isError: true,
            });
          });

        dispatch({ type: "REQUEST_SEARCH" });
      }
    },
};

export const RECEIVE_SEARCH = "RECEIVE_SEARCH";
export const REQUEST_SEARCH = "REQUEST_SEARCH";

interface RECEIVE_SEARCH {
  type: "RECEIVE_SEARCH";
  payload: {
    results: ResultItem[];
  };
  isError: boolean;
}
interface REQUEST_SEARCH {
  type: "REQUEST_SEARCH";
}

export type KnownAction = RECEIVE_SEARCH | REQUEST_SEARCH;
