import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Tilt from 'react-parallax-tilt';

import DASHMain from "./DASH/Main";
import Home from "./Home";
// import VanillaTilt from 'vanilla-tilt';
import { Container } from "reactstrap";
// @ts-ignore
import * as Phys from "react-dom-box2d";

type MainProps = RouteComponentProps<{ appcode: string }>; // ... plus incoming routing parameters

export class Main extends PureComponent<MainProps> {
  state = {};

  render() {


    if (this.props.match.params.appcode) {
      let theAppcode = this.props.match.params.appcode;
      return (
        <>
          <DASHMain appcode={theAppcode}></DASHMain>
        </>
      );
    } else {
      return <>
          {/* <Tilt gyroscope={true} tiltMaxAngleY={90} tiltAxis={"y"}>

            <div style={{ height: '300px', backgroundColor: 'darkgreen' }}>
              <h1>React Parallax Tilt 👀</h1>
            </div>
          </Tilt> */}

          <Phys.World width={400} height={400} gravity={[0, 9.8]} className="world">
            <Phys.Item left={5} top={100} restitution={0.8}>
              <div className="box1">
                Some text3 with <button>button</button> or any
                <input type="text" placeholder="other html elements" />

              </div>
            </Phys.Item>

            {/* <Phys.Item shape="box" left={240} top={3} restitution={0.85}>
              <div className="circle1">Box too</div>
            </Phys.Item> */}
            <Phys.Item shape="circle" left={240} top={3} height={300} width={300} restitution={0.85}>
              <div className="circle1">circle too</div>
            </Phys.Item>
          </Phys.World>

        <Home></Home>   </>;
    }
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
