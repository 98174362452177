import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { AnyAction, bindActionCreators } from "redux";
import { ApplicationState } from "../../../store";
import * as WASCIState from "../../../store/WASCIReducer";
import * as WASCIAction from "../../../store/WASCI";
import { ThunkDispatch } from "redux-thunk";

// import LoadingModal from "../../modals/LoadingModal.tsx.stash";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import { UseCase } from "../../../store/Interfaces";

// At runtime, Redux will merge together...
type boilerProps = WASCIState.State & // ... state we've requested from the Redux store
  typeof WASCIAction.actionCreators &
  IActions & // ... plus action creators we've requested
  OwnProps &
  RouteComponentProps<{ appcode: string }>; // ... plus incoming routing parameters
interface IActions {
  actions: {
    wasciActions: AnyAction;
  };
}
class UseCaseWidget extends React.PureComponent<boilerProps> {
  public state = {
    activeIndex: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    animating: false,
  };

  public componentWillUnmount() {
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();

  }

  render() {
    let currentAppData = this.props.wascis.appData.get(this.props.appcode);
    currentAppData &&
      currentAppData.useCaseData &&
      currentAppData.useCaseData.sort(function (a, b) {
        return a.groupid - b.groupid;
      });

    let groupById = function (xs: UseCase[] | undefined, key: string) {
      if (xs) {
        return xs.reduce(function (rv: UseCase[][], x) {
          (rv[x.id] = rv[x.id] || []).push(x);
          return rv;
        }, []);
      } else {
        return [];
      }
    };

    let groupBygroupId = function (xs: UseCase[] | undefined, key: string) {
      if (xs) {
        return xs.reduce(function (rv: UseCase[][], x) {
          (rv[x.groupid] = rv[x.groupid] || []).push(x);
          return rv;
        }, []);
      } else {
        return [];
      }
    };
    let grouped = currentAppData
      ? this.props.grouping === false
        ? groupById(currentAppData.useCaseData, "id")
        : groupBygroupId(currentAppData.useCaseData, "groupid")
      : [];

    // //console.log(grouped);

    if (currentAppData && currentAppData.useCaseData && grouped.length > 0) {
      return (
        <div key="main">
          <div className="row d-flex flex-row justify-content-around align-items-center ml-4">
            <h2>Use Cases</h2>
          </div>
          {grouped.map((usecase, index) => {
            let thedata: JSX.Element[] = [];
            grouped[index].forEach((value, key, map) => {
              if (value.id) {
                thedata.push(
                  <CarouselItem
                    className="carousel-tag"
                    tag="div"
                    key={
                      "caritem" + value.groupid.toString() + (value && value.id)
                        ? value.id.toString()
                        : ""
                    }
                    onExiting={() => this.setAnimating(true)}
                    onExited={() => this.setAnimating(false)}
                  >
                    {/* <img
                    src={`data:image/jpeg;base64,${value.theimage}`}
                    className="img-bohrapfluid"
                  /> */}
                    <div className="row d-flex flex-row justify-content-around align-items-center ml-4">
                      <h2 className="fw-bold text-white">
                        {value.description}
                      </h2>
                      <a href={value.url} target="_blank" rel="noopener noreferrer">{value.url} </a>
                    </div>
                    <img
                      src={
                        process.env.REACT_APP_API_HOST +
                        `/Think/usecaseImage?id=${value.id}`
                      }
                      className="img-bohrapfluid"
                      alt={value.description}
                    />
                  </CarouselItem>
                );
              }
            });

            let localprevious = () => {
              //console.log("PREVIOUS" + index.toString());

              let count = thedata ? thedata.length : 0;

              if (this.state.animating) return;
              let currentActiveIndex = this.state.activeIndex;
              currentActiveIndex[index] =
                this.state.activeIndex[index] === 0
                  ? count - 1
                  : this.state.activeIndex[index] - 1;
              this.setState({
                activeIndex: currentActiveIndex,
                animating: this.state.animating,
              });

              this.forceUpdate();
              //console.log("activeINDEX" + this.state.activeIndex);
            };

            let localnext = () => {
              let count = thedata ? thedata.length : 0;
              if (this.state.animating) return;
              let currentActiveIndex = this.state.activeIndex;

              currentActiveIndex[index] =
                this.state.activeIndex[index] === count - 1
                  ? 0
                  : this.state.activeIndex[index] + 1;
              this.setState({
                activeIndex: currentActiveIndex,
                animating: this.state.animating,
              });

              this.forceUpdate();
              //console.log("activeINDEX" + this.state.activeIndex);
            };

            let localgoToIndex = (newIndex: number) => {
              if (this.state.animating) return;

              let currentActiveIndex = this.state.activeIndex;
              currentActiveIndex[index] = newIndex;
              this.setState({
                activeIndex: currentActiveIndex,
                animating: this.state.animating,
              });
              this.forceUpdate();
            };

            //console.log("GROUPINDEX" + index.toString());
            //console.log("activeINDEX" + this.state.activeIndex);
            return (
              <div
                key={"rowgroup" + index.toString()}
                className="row d-flex flex-row"
              >
                <div className="col-xs-12 col-lg-12 bohrap-container">
                  <Carousel
                    key={"cargroup" + index.toString()}
                    activeIndex={
                      this.state.activeIndex[index]
                        ? this.state.activeIndex[index]
                        : 0
                    }
                    next={localnext}
                    previous={localprevious}
                    slide={false}
                  >
                    <CarouselIndicators
                      items={thedata}
                      activeIndex={
                        this.state.activeIndex[index]
                          ? this.state.activeIndex[index]
                          : 0
                      }
                      onClickHandler={localgoToIndex}
                    />

                    {thedata}
                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={localprevious}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={localnext}
                    />
                  </Carousel>
                </div>
              </div>
            );
          })}
          {/* <LoadingModal
            controlid="UseCase"
          controlIsLoading ={undefined}
          ></LoadingModal> */}
        </div>
      );
    } else {
      return (
        <div key="rm">
          {/* <LoadingModal
            controlid="UseCase"
          controlIsLoading ={undefined}
          ></LoadingModal> */}
        </div>
      );
    }
  }
  setAnimating(arg0: boolean): void {
    this.setState({
      activeIndex: this.state.activeIndex,
      animating: arg0,
    });
  }

  private ensureDataFetched() {
    this.props.actions.wasciActions.requestusecase(
      "UseCase",
      this.props.appcode,
      () => {
        this.forceUpdate();
      }
    );
  }
}

interface OwnProps {
  appcode: string;
  grouping: boolean | undefined;
  controlIsLoading: boolean;
}
const mapStateToProps = function (state: ApplicationState, ownProps: OwnProps) {
  let controlIsLoading =
    state.wascistate && state.wascistate.isLoading.get("UseCase");
  controlIsLoading = controlIsLoading ? controlIsLoading : false;
  return {
    wascis: state.wascistate ? state.wascistate.wascis : undefined,
    appcode: ownProps.appcode,
    grouping: ownProps.grouping,
    controlIsLoading: controlIsLoading,
  };
};

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
  return {
    actions: {
      wasciActions: bindActionCreators(WASCIAction.actionCreators, dispatch),
    },
  };
}
export default connect(
  mapStateToProps, // Selects which state properties are merged into the component's props
  mapDispatchToProps // Selects which action creators are merged into the component's props
)(UseCaseWidget as any);
