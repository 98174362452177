import { AppThunkAction } from "..";
import { Types } from "./types";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestactivitycount: (
    webappcode: string | undefined,
    stack: string | undefined,
    myCallback: () => void
  ): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();

    if ( 
      appState &&
      appState.chartstate 
    ) {
      fetch(process.env.REACT_APP_API_HOST + '/Watch/HA/ActivityByHour?stack='+ stack +'&mindatetime=2021-6-15', {
        method: "GET",
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
        .then((response) => response.json() as Promise<Types.ActivityCount[]>)
        .then((data) => {
          dispatch({
            type: "RECEIVE_ALL_ACTIVITYCOUNT",
            payload: { activitycount: data },
            webappcode: webappcode?webappcode:"",
            isError: false,
          });
          myCallback();
        })
        .catch((error) => {
          // dispatch({
          //   type: "RECEIVE_ALL_ACTIVITYCOUNT",
          //   payload: {
          //       activitycount: appState.chartstate ? appState.chartstate.activitycount.get(webappcode?webappcode:"") : [],
          //   },
          //   webappcode: webappcode?webappcode:"",
          //   isError: true,
          // });
        });

      dispatch({ type: "REQUEST_ALL_ACTIVITYCOUNT" });
    }
  },
  requestwebappcount: (
    webappcode: string | undefined,
    stack: string | undefined,
    myCallback: () => void
  ): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();

    if ( 
      appState &&
      appState.chartstate 
    ) {
      fetch(process.env.REACT_APP_API_HOST + '/Watch/HA/WebAppByHour?stack='+ stack +'&mindatetime=2021-6-15', {
        method: "GET",
        credentials: "include",
        headers: {
          pragma: "no-cache",
          "cache-control": "no-cache",
        },
      })
        .then((response) => response.json() as Promise<Types.WebAppCount[]>)
        .then((data) => {
          dispatch({
            type: "RECEIVE_ALL_WEBAPPCOUNT",
            payload: { webappcount: data },
            webappcode: webappcode?webappcode:"",
            isError: false,
          });
          myCallback();
        })
        .catch((error) => {
          // dispatch({
          //   type: "RECEIVE_ALL_WEBAPPCOUNT",
          //   payload: {
          //       webappcount: appState.chartstate ? appState.chartstate.webappcount.get(webappcode?webappcode:"") : [],
          //   },
          //   webappcode: webappcode?webappcode:"",
          //   isError: true,
          // });
        });

      dispatch({ type: "REQUEST_ALL_ACTIVITYCOUNT" });
    }
  }
};

export const RECEIVE_ALL_ACTIVITYCOUNT = "RECEIVE_ALL_ACTIVITYCOUNT";
export const REQUEST_ALL_ACTIVITYCOUNT = "REQUEST_ALL_ACTIVITYCOUNT";

export const RECEIVE_ALL_WEBAPPCOUNT = "RECEIVE_ALL_WEBAPPCOUNT";
export const REQUEST_ALL_WEBAPPCOUNT = "REQUEST_ALL_WEBAPPCOUNT";

interface RECEIVE_ALL_ACTIVITYCOUNT {
  type: "RECEIVE_ALL_ACTIVITYCOUNT";
  payload: {
    activitycount: Types.ActivityCount[] | undefined;
  };
  webappcode: string;
  isError: boolean;
}
interface REQUEST_ALL_ACTIVITYCOUNT {
  type: "REQUEST_ALL_ACTIVITYCOUNT";
}

interface RECEIVE_ALL_WEBAPPCOUNT {
    type: "RECEIVE_ALL_WEBAPPCOUNT";
    payload: {
      webappcount: Types.WebAppCount[]| undefined;
    };
    webappcode: string;
    isError: boolean;
  }
  interface REQUEST_ALL_WEBAPPCOUNT {
    type: "REQUEST_ALL_WEBAPPCOUNT";
  }

export type KnownAction =
  RECEIVE_ALL_ACTIVITYCOUNT
  | REQUEST_ALL_ACTIVITYCOUNT
  | RECEIVE_ALL_WEBAPPCOUNT
  | REQUEST_ALL_WEBAPPCOUNT;