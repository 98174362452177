import {
  KnownAction,
  REQUEST_ALL_CREDENTIALS,
  RECEIVE_ALL_CREDENTIALS,
  REQUEST_INSERT_CREDENTIAL,
  REQUEST_SETISMODALOPEN,
} from "./CredentialAction";
import { createStore, Store } from "redux";
import { Credential } from "./CredentialInterfaces";


export interface State {
  credentials: Credential[];
  isLoading: Map<string, boolean>;
  isError: boolean;
  credentialModalOpen: boolean;
  random: number;
}

export const initialState: State = {
  credentials: [],
  isLoading: new Map(),
  isError: false,
  credentialModalOpen: false,
  random: Math.floor(Math.random() * Math.floor(1000)),
};

export const reducer = (state = initialState, action: KnownAction): State => {
  switch (action.type) {
    case REQUEST_ALL_CREDENTIALS: {
      let newstate = { ...state };
      newstate.isLoading.set(action.controlid, true);
      newstate.random = Math.floor(Math.random() * Math.floor(1000)); 
      return newstate;
    }
    case REQUEST_SETISMODALOPEN: {
      let newstate = { ...state };
      newstate.credentialModalOpen = action.credentialModalOpen;
      newstate.random = Math.floor(Math.random() * Math.floor(1000));
      return newstate;
    }
    case REQUEST_INSERT_CREDENTIAL: {
      let newstate = { ...state };
      newstate.isLoading.set(action.controlid, false);
      newstate.random = Math.floor(Math.random() * Math.floor(1000));
      return newstate;
    }
    case RECEIVE_ALL_CREDENTIALS: {
      let newstate = { ...state };
      newstate.credentials = action.payload.credentials;
      newstate.isError = action.isError;
      newstate.isLoading.set(action.controlid, false);
      newstate.random = Math.floor(Math.random() * Math.floor(1000));
      return newstate;
    }

    default:
      return state;
  }
};

const store: Store<State, KnownAction> = createStore(reducer);

export { store };
