import { AppThunkAction } from ".";
import { ActivityItem, BreakdownItem, ErrorItem } from "./Interfaces";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestactivity:
    (
      appcode: string,
      stack: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      stack = stack ? stack : "prod";
      let mindatetime = "";

      if (appState.activitystate) {
        let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
          appState.activitystate.start
        );
        let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
          appState.activitystate.start
        );
        let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
          appState.activitystate.start
        );

        mindatetime = `${ye}-${mo}-${da}`;
      }

      
      const fullurl = "/api/webappstats/?stack=" + stack + "&mindatetime=" +
      mindatetime;

      if (appState && appState.activitystate) {
        //fetch(process.env.REACT_APP_API_HOST + `/watch/search?search=` + search + `&stack=` + stack + `&appcode=` + appcode, {
        fetch(process.env.REACT_APP_API_HOST + fullurl, {
          method: "GET",
          credentials: "include",
          headers: {
            pragma: "no-cache",
            "cache-control": "no-cache",
          },
        })
          .then((response) => response.json() as Promise<ActivityItem[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_ACTIVITY",
              payload: { activity: data },
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_ACTIVITY",
              payload: {
                activity:
                  appState.activitystate && appState.activitystate.activity,
              },
              isError: true,
            });
          });

        dispatch({ type: "REQUEST_ACTIVITY" });
      }
    },
  requesterrors:
    (
      appcode: string,
      stack: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      stack = stack ? stack : "prod";
      let mindatetime = "";

        if (appState.activitystate) {
          let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
            appState.activitystate.start
          );
          let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
            appState.activitystate.start
          );
          let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
            appState.activitystate.start
          );

          mindatetime = `${ye}-${mo}-${da}`;
        }

      const fullurl = "/api/errors/exceptions/?stack=" + stack + "&mindatetime=" +
        mindatetime;

      if (appState && appState.activitystate) {
        //fetch(process.env.REACT_APP_API_HOST + `/watch/search?search=` + search + `&stack=` + stack + `&appcode=` + appcode, {
        fetch(process.env.REACT_APP_API_HOST + fullurl, {
          method: "GET",
          credentials: "include",
          headers: {
            pragma: "no-cache",
            "cache-control": "no-cache",
          },
        })
          .then((response) => response.json() as Promise<ErrorItem[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_ERRORS",
              payload: { errors: data },
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_ERRORS",
              payload: {
                errors: appState.activitystate && appState.activitystate.errors,
              },
              isError: true,
            });
          });

        dispatch({ type: "REQUEST_ERRORS" });
      }
    },
  requestbreakdown:
    (
      appcode: string,
      stack: string,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      stack = stack ? stack : "prod";

      let mindatetime = "";

        if (appState.activitystate) {
          let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
            appState.activitystate.start
          );
          let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
            appState.activitystate.start
          );
          let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
            appState.activitystate.start
          );

          mindatetime = `${ye}-${mo}-${da}`;
        }

      const fullurl =
        "/api/webapps/?columns=webappcode,count(sessionid)totalsessions&groupby=webappcode&stack=" +
        stack + "&mindatetime=" +
        mindatetime;

      if (appState && appState.activitystate) {
        //fetch(process.env.REACT_APP_API_HOST + `/watch/search?search=` + search + `&stack=` + stack + `&appcode=` + appcode, {
        fetch(process.env.REACT_APP_API_HOST + fullurl, {
          method: "GET",
          credentials: "include",
          headers: {
            pragma: "no-cache",
            "cache-control": "no-cache",
          },
        })
          .then((response) => response.json() as Promise<BreakdownItem[]>)
          .then((data) => {
            dispatch({
              type: "RECEIVE_BREAKDOWN",
              payload: { breakdown: data },
              isError: false,
            });
            myCallback();
          })
          .catch((error) => {
            dispatch({
              type: "RECEIVE_BREAKDOWN",
              payload: {
                breakdown:
                  appState.activitystate && appState.activitystate.breakdown,
              },
              isError: true,
            });
          });

        dispatch({ type: "REQUEST_BREAKDOWN" });
      }
    },
  setStack:
    (stack: string, myCallback: () => void): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (
        appState &&
        appState.activitystate &&
        appState.activitystate.stack !== stack
      ) {
        dispatch({
          type: "REQUEST_SETSTACK",
          stack: stack,
        });
        myCallback();
      }
    },
  setDateRange:
    (
      start: Date,
      end: Date,
      label: string | undefined,
      myCallback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (
        appState &&
        appState.activitystate &&
        (appState.activitystate.start !== start ||
          appState.activitystate.end !== end ||
          appState.activitystate.label !== label)
      ) {
        dispatch({
          type: "REQUEST_SETDATERANGE",
          start: start,
          end: end,
          label: label,
        });
        myCallback();
      }
    },
};

export const RECEIVE_ACTIVITY = "RECEIVE_ACTIVITY";
export const REQUEST_ACTIVITY = "REQUEST_ACTIVITY";
export const RECEIVE_ERRORS = "RECEIVE_ERRORS";
export const REQUEST_ERRORS = "REQUEST_ERRORS";
export const RECEIVE_BREAKDOWN = "RECEIVE_BREAKDOWN";
export const REQUEST_BREAKDOWN = "REQUEST_BREAKDOWN";
export const REQUEST_SETSTACK = "REQUEST_SETSTACK";
export const REQUEST_SETDATERANGE = "REQUEST_SETDATERANGE";

interface RECEIVE_ACTIVITY {
  type: "RECEIVE_ACTIVITY";
  payload: {
    activity: ActivityItem[] | undefined;
  };
  isError: boolean;
}
interface REQUEST_ACTIVITY {
  type: "REQUEST_ACTIVITY";
}
interface RECEIVE_ERRORS {
  type: "RECEIVE_ERRORS";
  payload: {
    errors: ErrorItem[] | undefined;
  };
  isError: boolean;
}
interface REQUEST_ERRORS {
  type: "REQUEST_ERRORS";
}
interface RECEIVE_BREAKDOWN {
  type: "RECEIVE_BREAKDOWN";
  payload: {
    breakdown: BreakdownItem[] | undefined;
  };
  isError: boolean;
}
interface REQUEST_BREAKDOWN {
  type: "REQUEST_BREAKDOWN";
}
interface REQUEST_SETSTACK {
  type: "REQUEST_SETSTACK";
  stack: string;
}
interface REQUEST_SETDATERANGE {
  type: "REQUEST_SETDATERANGE";
  start: Date;
  end: Date;
  label: string | undefined;
}
export type KnownAction =
  | RECEIVE_ACTIVITY
  | REQUEST_ACTIVITY
  | REQUEST_ERRORS
  | RECEIVE_ERRORS
  | REQUEST_BREAKDOWN
  | RECEIVE_BREAKDOWN
  | REQUEST_SETSTACK
  | REQUEST_SETDATERANGE;
